<button (click)="openPanel()" #userSelectorOrigin type="button" class="flex items-center">
    <ng-content select="[triggerButtonContent]"></ng-content>
</button>

<!-- Shortcuts panel -->
<ng-template #userSelectorPanel>
    <div class="fixed inset-0 z-9999 sm:static sm:inset-auto flex flex-col sm:rounded-md overflow-hidden shadow-lg ng-star-inserted sm:min-w-90 sm:w-90" *ngrxLet="loading$; let loading">
        <!-- Header -->
        <div class="flex shrink-0-0 items-center py-1 px-4 space-x-8 bg-gray-200">
            <mat-form-field class="flex-auto fuse-mat-no-subscript fuse-mat-dense">
                <input matInput [formControl]="userFilter" [placeholder]="searchPlaceholder" />
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
            </mat-form-field>

            <div class="ml-auto flex space-x-2">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
        </div>

        @if (viewSettings.team_switcher){
        <div class="flex shrink-0-0 justify-between items-center py-1 px-4 space-x-8 bg-gray-200">
            <span>{{ model }}s</span>
            <mat-slide-toggle [checked]="model === 'Team'" (toggleChange)="model === 'User' ? setModel('Team') : setModel('User')">Teams</mat-slide-toggle>
        </div>
        }

        <ng-container *ngIf="data$ | ngrxPush as data">
            <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card p-4" *ngIf="data.length > 0" (scroll)="onScroll($event)">
                <div class="flex flex-col divide-y" *ngIf="model === 'User'">
                    <ng-container *ngTemplateOutlet="usersSelectorTemplate; context: { users: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Team'">
                    <ng-container *ngTemplateOutlet="teamsSelectorTemplate; context: { teams: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Courier'">
                    <ng-container *ngTemplateOutlet="couriersSelectorTemplate; context: { couriers: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Address'">
                    <ng-container *ngTemplateOutlet="addressesSelectorTemplate; context: { addresses: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Device'">
                    <ng-container *ngTemplateOutlet="devicesSelectorTemplate; context: { devices: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Customer'">
                    <ng-container *ngTemplateOutlet="customersSelectorTemplate; context: { customers: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Contact'">
                    <ng-container *ngTemplateOutlet="contactsSelectorTemplate; context: { contacts: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'POITarget'">
                    <ng-container *ngTemplateOutlet="poiTargetsSelectorTemplate; context: { targets: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Shipment'">
                    <ng-container *ngTemplateOutlet="shipmentTemplate; context: { shipments: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'Order'">
                    <ng-container *ngTemplateOutlet="orderTemplate; context: { orders: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'OrderItem'">
                    <ng-container *ngTemplateOutlet="orderItemTemplate; context: { orderItems: data }"></ng-container>
                </div>
                <div class="flex flex-col divide-y" *ngIf="model === 'CourierTracking'">
                    <ng-container *ngTemplateOutlet="trackingTemplate; context: { trackings: data }"></ng-container>
                </div>
            </div>

            <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card p-4 items-center justify-center" *ngIf="data.length === 0 && !loading">
                <div class="flex flex-0 items-center justify-center w-8 h-8 rounded-full bg-primary-100">
                    <mat-icon class="text-primary-500" [svgIcon]="'heroicons_outline:terminal'"></mat-icon>
                </div>
                <div class="mt-5 text-2xl font-semibold tracking-tight">No {{ model }}s found</div>
            </div>

            <div class="w-full mx-auto" *ngIf="loading">
                <div class="animate-pulse flex">
                    <div class="flex-1 space-y-4 py-1">
                        <div class="h-4 bg-gray-400 rounded w-full"></div>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="flex justify-end bg-card p-2 border-t border-solid border-gray-200">
            <span>Page {{ page$ | async }} of {{ lastPage$ | async }}</span>
        </div>
    </div>
</ng-template>

<ng-template #usersSelectorTemplate let-users="users">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let user of users" (click)="selectModel(user)">
        <img
            class="w-12 h-12 rounded-full object-cover border-solid border-2 border-white shadow-lg"
            [src]="user.avatar || 'assets/images/avatar.png'"
            [matTooltip]="'User: ' + user.name"
            [alt]="user.name"
        />

        <div class="flex flex-col">
            <span>{{ user.name }}</span>
            <span class="text-secondary">{{ user.email }}</span>
        </div>
    </div>
</ng-template>

<ng-template #teamsSelectorTemplate let-teams="teams">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let team of teams" (click)="selectModel(team)">
        <div class="flex flex-col" [matTooltip]="team.description">
            <span>{{ team.name }}</span>
            <span class="text-secondary truncate">{{ team.description }}</span>
        </div>
    </div>
</ng-template>

<ng-template #couriersSelectorTemplate let-couriers="couriers">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let courier of couriers" (click)="selectModel(courier)">
        <img
            class="w-12 h-12 rounded-full object-cover border-solid border-2 border-white shadow-lg"
            [src]="courier.icon_path || 'assets/images/avatar.png'"
            [matTooltip]="'Courier: ' + courier.display_name"
            [alt]="courier.display_name"
        />

        <div class="flex flex-col">
            <span>{{ courier.display_name }}</span>
            <span class="text-secondary">{{ courier.slug }}</span>
        </div>
    </div>
</ng-template>

<ng-template #addressesSelectorTemplate let-addresses="addresses">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let address of addresses" (click)="selectModel(address)">
        <div class="flex flex-col" [matTooltip]="address.address">
            <span>{{ [address.address, address.address2].join(", ") }}</span>
            <span class="text-secondary truncate">{{ address.city }}, {{ address.zip }}, {{ address.country }}({{ address.country_iso_2 }})</span>
        </div>
    </div>
</ng-template>

<ng-template #customersSelectorTemplate let-customers="customers">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let customer of customers" (click)="selectModel(customer)">
        <span>{{ customer.name }}</span>
    </div>
</ng-template>

<ng-template #contactsSelectorTemplate let-contacts="contacts">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let contact of contacts" (click)="selectModel(contact)">
        <div class="flex flex-col">
            <span>{{ contact.first_name }} {{ contact.last_name }}</span>
            <span class="text-secondary truncate">{{ contact.email }} ({{ contact.phone }})</span>
        </div>
    </div>
</ng-template>

<ng-template #devicesSelectorTemplate let-devices="devices">
    <div class="flex flex-col space-y-2 py-2 cursor-pointer" *ngFor="let device of devices" (click)="selectModel(device)">
        <div class="flex space-x-2">
            <span class="font-bold">Ref:</span>
            <span>{{ device.ref }}</span>
        </div>
        <app-device-preview class="flex flex-row justify-between w-full" [device]="device"></app-device-preview>
        <app-device-tags class="w-full overflow-x-auto" [device]="device"></app-device-tags>
    </div>
</ng-template>

<ng-template #poiTargetsSelectorTemplate let-targets="targets">
    <div class="flex flex-row items-center space-x-4 py-2 cursor-pointer" *ngFor="let target of targets" (click)="selectModel(target)">
        <span class="grow">
            {{ target.name }}
            <span *ngIf="target.code" class="text-slate-500 text-sm">{{ target.code }}</span>
        </span>
        <span class="whitespace-nowrap text-slate-500 text-sm" *ngIf="target.city">{{ target.city }} ({{ target.iso_country }})</span>
    </div>
</ng-template>

<ng-template #shipmentTemplate let-shipments="shipments">
    <div class="flex flex-col py-2 cursor-pointer" *ngFor="let shipment of shipments" (click)="selectModel(shipment)">
        <span class="grow pb-2">{{ shipment.ref }}</span>

        <ng-container *ngIf="viewSettings.from_address ?? false">
            <div class="flex flex-col overflow-auto" *ngIf="shipment.from_address">
                <span class="text-secondary whitespace-nowrap text-sm mb-1">Customer: {{ shipment.from_address.customer.name }}</span>
                <span class="whitespace-nowrap text-sm">{{ [shipment.from_address.address, shipment.from_address.address2].join(", ") }}</span>
                <span class="whitespace-nowrap text-sm font-bold">
                    {{ shipment.from_address.city }}, {{ shipment.from_address.zip }} , {{ shipment.from_address.country }}({{ shipment.from_address.country_iso_2 }})
                </span>
                <span class="text-secondary whitespace-nowrap text-sm mt-1">Contact: {{ shipment.from_contact?.first_name }} {{ shipment.from_contact?.last_name }}</span>
            </div>

            <div class="flex flex-col overflow-auto" *ngIf="shipment.from_port">
                <span class="whitespace-nowrap text-sm">{{ shipment.from_port.name }} ({{ shipment.from_port.code }})</span>
                <span class="whitespace-nowrap text-sm font-bold">{{ shipment.from_port.municipality }}, {{ shipment.from_port.country_iso_2 }}</span>
            </div>

            <div class="flex flex-col overflow-auto" *ngIf="shipment.from_warehouse">
                <span class="whitespace-nowrap text-sm">{{ shipment.from_warehouse.name }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="viewSettings.to_address ?? false">
            <div class="flex flex-col overflow-auto" *ngIf="shipment.to_address">
                <span class="text-secondary whitespace-nowrap text-sm mb-1">Customer: {{ shipment.to_address.customer.name }}</span>
                <span class="whitespace-nowrap text-sm">{{ [shipment.to_address.address, shipment.to_address.address2].join(", ") }}</span>
                <span class="whitespace-nowrap text-sm font-bold">
                    {{ shipment.to_address.city }}, {{ shipment.to_address.zip }} , {{ shipment.to_address.country }}({{ shipment.to_address.country_iso_2 }})
                </span>
                <span class="text-secondary whitespace-nowrap text-sm mt-1">Contact: {{ shipment.to_contact?.first_name }} {{ shipment.to_contact?.last_name }}</span>
            </div>

            <div class="flex flex-col overflow-auto" *ngIf="shipment.to_port">
                <span class="whitespace-nowrap text-sm">{{ shipment.to_port.name }} ({{ shipment.to_port.code }})</span>
                <span class="whitespace-nowrap text-sm font-bold">{{ shipment.to_port.municipality }}, {{ shipment.to_port.country_iso_2 }}</span>
            </div>

            <div class="flex flex-col overflow-auto" *ngIf="shipment.to_warehouse">
                <span class="whitespace-nowrap text-sm">{{ shipment.to_warehouse.name }}</span>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #orderTemplate let-orders="orders">
    <div class="flex flex-col py-2 cursor-pointer" *ngFor="let order of orders" (click)="selectModel(order)">
        <div class="flex mb-2">
            <span class="min-w-22 w-22 text-gray-500 font-bold">REF</span>
            <span class="font-bold">{{ order.ref }}</span>
        </div>
        <div class="flex mb-1" *ngIf="order.contact?.address?.customer">
            <span class="min-w-22 w-22 text-gray-500 font-bold">Customer</span>
            <span>{{ order.contact.address.customer.name }}</span>
        </div>
        <div class="flex mb-1" *ngIf="order.contact?.address">
            <span class="min-w-22 w-22 text-gray-500 font-bold">Address</span>
            <span>
                <span>{{ [order.contact.address.address, order.contact.address.address2].join(", ") }}</span>
                ,
                <span class="font-bold">
                    {{ order.contact.address.city }}, {{ order.contact.address.zip }}, {{ order.contact.address.country }}({{ order.contact.address.country_iso_2 }})
                </span>
            </span>
        </div>
        <div class="flex" *ngIf="order.contact">
            <span class="min-w-22 w-22 text-gray-500 font-bold">Contact</span>
            <span>{{ order.contact.first_name }} {{ order.contact.last_name }}</span>
        </div>
    </div>
</ng-template>

<ng-template #orderItemTemplate let-orderItems="orderItems">
    <div class="flex flex-col py-2 cursor-pointer" *ngFor="let orderItem of orderItems" (click)="selectModel(orderItem)">
        <div class="flex mb-2">
            <span class="min-w-22 w-22 text-gray-500 font-bold">Id</span>
            <span class="font-bold">{{ orderItem.id }}</span>
        </div>

        <div class="flex mb-1" *ngIf="orderItem.part_master">
            <span class="min-w-22 w-22 text-gray-500 font-bold">Part Master</span>
            <span>{{ orderItem.part_master.item }}</span>
        </div>
    </div>
</ng-template>

<ng-template #trackingTemplate let-trackings="trackings">
    <div class="flex flex-col space-y-2 py-2 cursor-pointer" *ngFor="let tracking of trackings" (click)="selectModel(tracking)">
        <div class="flex space-x-2">
            <span class="font-bold">Tracking number:</span>
            <span>{{ tracking.tracking_number }}</span>
        </div>
    </div>
</ng-template>
