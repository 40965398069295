import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import * as L from 'leaflet';
import {
    circle,
    DivIcon,
    divIcon,
    DrawEvents,
    DrawMap,
    featureGroup,
    Icon,
    latLng,
    LeafletMouseEvent,
    Map,
    MapOptions,
    marker,
    Marker,
    polygon,
    polyline,
    tileLayer
} from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.motion/dist/leaflet.motion.min';

import {
    IMapCircle,
    IMapClustersConfiguration,
    IMapCoordinates,
    IMapIconOptions,
    IMapInfoPopupDefinition,
    IMapLegend,
    IMapLocationInfoComponent,
    IMapPoint,
    IMapPointInfoType,
    IMapPolygon,
    IMapRoute,
    IMotion,
    MapConfiguration,
    polylineType
} from '@shared/components/leaflet-map/map-configuration';
import { GeocodingService } from '@core/services/services/geocoding.service';
import { replaceTemplate } from '@utils/helpers';
import { DataMarker } from '@shared/components/leaflet-map/leaflet-data-marker';
import { AppCoreSelectors } from '@store/app-core';
import { distinctUntilChanged } from 'rxjs/operators';
import { isAddress } from '@models/address';
import { iconSet } from '@shared/components/leaflet-map/icons-set';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GestureHandling } from 'leaflet-gesture-handling';

@Component({
    selector: 'app-common-leaflet-map',
    templateUrl: './leaflet-map.component.html',
    styleUrls: ['./leaflet-map.component.scss']
})
export class AppLeafletMapComponent implements OnInit {
    @Input() public mapConfiguration: MapConfiguration;
    @ViewChild('mapLegend', { static: true }) legend: ElementRef;

    map: Map;

    // Marker cluster stuff
    markerClusterGroup: L.MarkerClusterGroup;
    markerClusterData: any[] = [];
    markerClusterOptions: L.MarkerClusterGroupOptions;

    LeafIcon: any = Icon.extend({
        options: {
            iconSize: [48, 48],
            iconAnchor: [25, 45],
            popupAnchor: [1, -34]
        }
    });

    defaultMarkerIcon = new this.LeafIcon({ iconUrl: 'assets/icons/modern_rounded/device.svg' });

    toIcon = new this.LeafIcon({ iconUrl: 'assets/icons/modern_rounded/to.svg' });
    fromIcon = new this.LeafIcon({ iconUrl: 'assets/icons/modern_rounded/from.svg' });

    fromPolyIcon = new this.LeafIcon({ iconUrl: 'assets/icons/modern_rounded/home.svg' });
    toPolyIcon = new this.LeafIcon({ iconUrl: 'assets/icons/modern_rounded/device.svg' });

    iconSet = iconSet;

    circleIconTemplate =
        '<svg xmlns="http://www.w3.org/2000/svg" height="{diameter}" width="{diameter}">' +
        '<circle cx="{radius}px" cy="{radius}px" r="{radius}px" fill="{color}" />' +
        '</svg>';

    lightTileLayer = tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
    });

    darkTileLayer = tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
    });

    options: MapOptions & { gestureHandling: boolean } = {
        worldCopyJump: false,
        layers: [this.lightTileLayer],
        zoom: 5,
        center: latLng(0, 0),
        zoomControl: false,
        gestureHandling: true
    };

    layers = [];

    drawOptions = {
        draw: {
            polyline: false,
            polygon: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
            marker: false
        }
    };

    drawShape: L.Draw.Circle | L.Draw.Polygon;

    motionLine: any;
    motionPaused = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private geocodingService: GeocodingService,
        private coreSelectors: AppCoreSelectors,
        private viewContainerRef: ViewContainerRef,
        private destroyRef: DestroyRef
    ) {}

    ngOnInit(): void {
        this.mapConfiguration.buildMap.subscribe(fitBounds => {
            this.handleConfiguration(fitBounds);
        });

        this.mapConfiguration.setBounds.subscribe(bounds => {
            this.map.fitBounds(bounds);
            this.map.setZoom(10);
        });

        this.mapConfiguration.resetBounds.subscribe(() => {
            this.fitBounds();
        });

        this.mapConfiguration.startDrawing.subscribe(shapeOptions => {
            if (shapeOptions['shape'] === 'circle') {
                this.drawShape = new L.Draw.Circle(this.map as DrawMap, { shapeOptions });
            } else if (shapeOptions['shape'] === 'polygon') {
                this.drawShape = new L.Draw.Polygon(this.map as DrawMap, { shapeOptions });
            }

            // always recreate circle
            this.drawShape.enable();
        });

        this.mapConfiguration.cancelDrawing.subscribe(() => {
            if (this.drawShape) {
                this.drawShape.disable();
                this.drawShape = null;
            }
        });

        this.mapConfiguration.toggleMotion.subscribe(() => {
            if (this.motionPaused) {
                this.motionLine.motionResume();
            } else {
                this.motionLine.motionPause();
            }

            this.motionPaused = !this.motionPaused;
        });
    }

    onDrawCreated(e: DrawEvents.Created): void {
        this.mapConfiguration.drawingComplete.next(e);
    }

    onMapReady(map: Map): void {
        this.map = map;

        L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

        L.control
            .zoom({
                position: 'bottomleft'
            })
            .addTo(map);

        this.map.on('click', (event: LeafletMouseEvent) => {
            this.mapConfiguration.mapClicked.next({ lat: event.latlng.lat, lng: event.latlng.lng });
        });

        this.handleConfiguration(true);

        this.coreSelectors.scheme$
            .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
            .subscribe(scheme => {
                if (!this.map.hasLayer(this[scheme + 'TileLayer'])) {
                    this.map.removeLayer(this[(scheme === 'dark' ? 'light' : 'dark') + 'TileLayer']);
                    this[scheme + 'TileLayer'].addTo(this.map);
                }
            });
    }

    markerClusterReady(group: L.MarkerClusterGroup): void {
        this.markerClusterGroup = group;
    }

    mapBoundsChanged(ev): void {
        this.mapConfiguration.boundingBox.next(this.map.getBounds());
    }

    async handleConfiguration(fitBounds = true): Promise<void> {
        this.layers = [];

        this.parseCircles(this.mapConfiguration.configuration?.circles || []);
        this.parsePolylines(this.mapConfiguration.configuration?.polylines || []);
        await this.parsePoints(this.mapConfiguration.configuration?.points || []);
        await this.parseRoutes(this.mapConfiguration.configuration?.routes || []);
        this.parseClusters(this.mapConfiguration.configuration?.clusters);
        this.parseLegends(this.mapConfiguration.configuration?.legends || []);
        this.parsePolygon(this.mapConfiguration.configuration?.polygons || []);

        if (this.mapConfiguration.configuration?.motion) {
            this.parseMotion(this.mapConfiguration.configuration?.motion);
        }

        this.changeDetectorRef.detectChanges();

        if (fitBounds) {
            this.fitBounds();
        }

        this.map.invalidateSize();
    }

    fitBounds(): void {
        let bounds = featureGroup(this.layers).getBounds();

        if (this.mapConfiguration.configuration?.clusters) {
            bounds =
                bounds && bounds.isValid()
                    ? bounds.extend(this.markerClusterGroup.getBounds())
                    : this.markerClusterGroup.getBounds();
        }

        if (bounds.isValid()) {
            this.map.fitBounds(bounds);
        }
    }

    parseCircles(circles: IMapCircle[]): void {
        if (circles.length > 0) {
            const group = featureGroup();

            let mapCircle;

            circles.forEach(c => {
                mapCircle = circle([c.lat, c.lng], c.radius, {
                    fillColor: c.style.strokeColor,
                    weight: c.style.lineWidth,
                    fillOpacity: 0.5,
                    color: c.style.strokeColor
                });

                group.addLayer(mapCircle);
            });

            this.layers.push(group);
        }
    }

    parsePolygon(polygons: IMapPolygon[]): void {
        if (polygons.length > 0) {
            const group = featureGroup();

            polygons.forEach(p => {
                group.addLayer(
                    polygon(p.coords, {
                        fillColor: p.style.strokeColor,
                        weight: p.style.lineWidth,
                        fillOpacity: 0.5,
                        color: p.style.strokeColor
                    })
                );
            });

            this.layers.push(group);
        }
    }

    async parseRoutes(routes: IMapRoute[]): Promise<void> {
        if (routes.length > 0) {
            const routesGroup = featureGroup();
            for (const route of routes) {
                const routeFeatureGroup = featureGroup();

                const fromPosition = isAddress(route.from)
                    ? await this.geocodingService.getPositionFromAddress(route.from).toPromise()
                    : route.from;

                const toPosition = isAddress(route.to)
                    ? await this.geocodingService.getPositionFromAddress(route.to).toPromise()
                    : route.to;

                if (!route.config?.hideMarkers) {
                    routeFeatureGroup.addLayer(
                        new DataMarker([fromPosition.lat, fromPosition.lng], { icon: this.fromIcon })
                    );

                    routeFeatureGroup.addLayer(new DataMarker([toPosition.lat, toPosition.lng], { icon: this.toIcon }));
                }

                if (!route.config?.hidePolyline) {
                    routeFeatureGroup.addLayer(
                        polyline(
                            [
                                [fromPosition.lat, fromPosition.lng],
                                [toPosition.lat, toPosition.lng]
                            ],
                            {
                                color: '#4f46e5',
                                dashArray: '10',
                                opacity: 0.8
                            }
                        )
                    );
                }

                routesGroup.addLayer(routeFeatureGroup);
            }

            this.layers.push(routesGroup);
        }
    }

    async parsePoints(points: IMapPoint[]): Promise<void> {
        if (points.length > 0) {
            const group = featureGroup();
            const withoutCoordinatesCount = points.filter(
                p => !p.position && !(p.address?.lat || p.address?.lng)
            ).length;
            for (const point of points) {
                const position = point.position
                    ? point.position
                    : await this.geocodingService
                          .getPositionFromAddress(point.address, withoutCoordinatesCount < 10)
                          .toPromise();

                const icon = this.resolvePointIcon(point, this.defaultMarkerIcon);

                const mapMarker = new DataMarker([position.lat, position.lng], { icon });

                if (point.info) {
                    this.bindPopup(mapMarker, point.info, point.position);
                }

                if (point.data) {
                    mapMarker.setData(point.data);
                }

                mapMarker.on('click', (event: LeafletMouseEvent) => {
                    this.mapConfiguration.markerClicked.next(event.sourceTarget.data);
                });

                group.addLayer(mapMarker);
            }

            this.layers.push(group);
        }
    }

    parsePolylines(polylines: polylineType[]): void {
        const circleStyle = {
            fillColor: '#2DD4BF',
            fillOpacity: 0.1,
            // Stroke
            color: '#2DD4BF',
            opacity: 0.3,
            weight: 1
        };

        if (polylines.length > 0) {
            const polylinesGroup = featureGroup();
            for (const p of polylines) {
                const polylineItem = featureGroup();

                if (p.length > 0) {
                    polylineItem.addLayer(
                        polyline(
                            p.map(point => [point.lat, point.lng]),
                            p[0].options ?? {}
                        )
                    );
                }

                p.forEach((point, index, array) => {
                    let markerOptions = {};

                    if (point.icon) {
                        const markerIcon =
                            point.icon === 'default'
                                ? this.getCircleIcon(4, 8, point.options ? point.options.color : '#4f46e5')
                                : this.resolvePointIcon(point, this.defaultMarkerIcon);

                        markerOptions = {
                            icon: markerIcon
                        };
                    } else if (array.length === 1) {
                        markerOptions = { icon: this.fromPolyIcon };
                    } else if (index === 0 && array.length > 1) {
                        markerOptions = { icon: this.fromPolyIcon };
                    } else if (index === array.length - 1 && array.length > 1) {
                        markerOptions = { icon: this.toPolyIcon };
                    } else {
                        markerOptions = {
                            icon: this.getCircleIcon(4, 8, point.options ? point.options.color : '#4f46e5')
                        };
                    }

                    const mapMarker = marker([point.lat, point.lng], markerOptions);

                    if (point.info) {
                        this.bindPopup(mapMarker, point.info, point);
                    }

                    if (point.areaRadius) {
                        polylineItem.addLayer(circle([point.lat, point.lng], point.areaRadius, circleStyle));
                    }

                    polylineItem.addLayer(mapMarker);
                });

                polylinesGroup.addLayer(polylineItem);
            }

            this.layers.push(polylinesGroup);
        }
    }

    parseMotion(motion: IMotion | IMotion[]): void {
        const polylinesGroup = featureGroup();
        const polylineItem = featureGroup();
        const isSeq = Array.isArray(motion);

        const points = isSeq ? motion.flatMap(m => m.path) : motion.path;

        if (points.length < 1) {
            return;
        }

        this.motionLine = isSeq
            ? (L as any).motion.seq(motion.map(m => this.makeMotionPolyline(m)))
            : this.makeMotionPolyline(motion);

        polylineItem.addLayer(this.motionLine);

        points.forEach((point, index, array) => {
            let markerOptions = {};

            if (point.icon) {
                const markerIcon =
                    point.icon === 'default'
                        ? this.getCircleIcon(4, 8, point.options ? point.options.color : '#4f46e5')
                        : this.resolvePointIcon(point, this.defaultMarkerIcon);

                markerOptions = {
                    icon: markerIcon
                };
            } else if (array.length === 1) {
                markerOptions = { icon: this.fromPolyIcon };
            } else if (index === 0 && array.length > 1) {
                markerOptions = { icon: this.fromPolyIcon };
            } else {
                markerOptions = {
                    icon: this.getCircleIcon(4, 8, point.options ? point.options.color : 'rgba(79,70,229,0.31)')
                };
            }

            const mapMarker = marker([point.lat, point.lng], markerOptions);

            if (point.info) {
                this.bindPopup(mapMarker, point.info, point);
            }

            polylineItem.addLayer(mapMarker);
        });

        polylinesGroup.addLayer(polylineItem);
        this.layers.push(polylinesGroup);
    }

    private makeMotionPolyline(motion: IMotion): any {
        return (L as any).motion.polyline(
            motion.path.map(point => [point.lat, point.lng]),
            motion.options,
            {
                auto: true,
                //easing: (L as any).Motion.Ease.swing,
                //duration: 30000,
                ...(motion.motionOptions || {})
            },
            {
                removeOnEnd: motion.markerOptions?.removeOnEnd ?? false,
                showMarker: motion.markerOptions?.showMarker ?? true,
                icon: this.resolvePointIcon(motion.markerOptions?.icon ?? {}, this.defaultMarkerIcon)
            }
        );
    }

    parseClusters(clusters?: IMapClustersConfiguration): void {
        if (clusters) {
            const data: any[] = [];

            for (const clusterPoint of clusters.points) {
                const icon = clusters.pointTemplate
                    ? divIcon({ html: replaceTemplate(clusters.pointTemplate, clusterPoint.data) })
                    : this.defaultMarkerIcon;

                const mapMarker = new DataMarker([clusterPoint.lat, clusterPoint.lng], { icon });

                if (clusterPoint.data) {
                    mapMarker.setData(clusterPoint.data);
                }

                mapMarker.on('click', (event: LeafletMouseEvent) => {
                    this.mapConfiguration.markerClicked.next(event.sourceTarget.data);
                });

                data.push(mapMarker);
            }

            this.markerClusterData = data;
        }
    }

    parseLegends(legends: IMapLegend[]): void {
        const legend = new L.Control({ position: 'bottomright' });
        legend.onAdd = (): HTMLElement => this.legend.nativeElement;

        legend.addTo(this.map);
    }

    private resolvePointIcon(
        point: { divIcon?: any; icon?: any; iconOptions?: IMapIconOptions },
        defaultIcon: any
    ): any {
        return point.divIcon
            ? divIcon({
                  html: point.divIcon,
                  iconAnchor: [3, 3],
                  ...point.iconOptions,
                  className: 'map-custom-div-icon'
              })
            : point.icon
            ? new this.LeafIcon(this.iconSet[point.icon] ?? { iconUrl: point.icon })
            : defaultIcon;
    }

    private compileComponentPopup(coordinates: IMapCoordinates, definition: IMapInfoPopupDefinition): any {
        this.viewContainerRef.clear();

        const componentRef = this.viewContainerRef.createComponent<IMapLocationInfoComponent>(definition.component);

        if (definition.data) {
            // eslint-disable-next-line guard-for-in
            for (const key in definition.data) {
                componentRef.instance[key] = definition.data[key];
            }
        }

        if (definition.initializer) {
            definition.initializer(componentRef.instance);
        }

        componentRef.instance.coordinates = coordinates;
        componentRef.instance.action?.subscribe(action => {
            this.mapConfiguration.infoAction.next(action);
        });

        componentRef.changeDetectorRef.detectChanges();

        return componentRef.location.nativeElement;
    }

    private bindPopup(m: Marker, info: IMapPointInfoType, coordinates: IMapCoordinates): void {
        if (info instanceof IMapInfoPopupDefinition) {
            const definition: IMapInfoPopupDefinition = info;

            m.bindPopup(() => this.compileComponentPopup(coordinates, definition));
        } else {
            m.bindPopup(info as any);
        }
    }

    private getCircleIcon(radius, diameter, color): DivIcon {
        return divIcon({
            className: 'map-circle-icon',
            html: this.circleIconTemplate
                .replace(/\{radius\}/g, radius)
                .replace(/\{diameter\}/g, diameter)
                .replace(/\{color\}/g, color),
            iconSize: [diameter, diameter],
            iconAnchor: [radius, radius]
        });
    }
}
