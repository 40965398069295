<a
    *ngIf="indicator() as indicator"
    [routerLink]="['cockpit/bubbles' | urlTo]"
    [queryParams]="{ partner_id: partner().id }"
    (click)="$event.stopPropagation()"
    [matTooltip]="indicator.tooltip"
>
    <span class="flex h-3 w-3 relative">
        <span class="absolute inline-flex h-full w-full rounded-full opacity-45" [style.background-color]="indicator.color" [class.animate-ping]="indicator.animate"></span>
        <span class="relative inline-flex rounded-full h-3 w-3" [style.background-color]="indicator.color"></span>
    </span>
</a>
