<ng-container *ngIf="organisation$ | ngrxPush; let organisation">
    <div class="flex flex-col w-full h-full justify-between overflow-auto bg-card">
        <div *ngIf="selected" class="flex flex-col w-full">
            <div class="flex flex-row items-center h-16 min-h-16 text-white fixed w-full sm:w-100 z-10">
                <ng-container *ngIf="moduleEnabled$('uops') | ngrxPush">
                    <button
                        class="flex h-full items-center justify-center relative bg-default hover:bg-primary-900 p-4 transition-all duration-300 ease-in-out"
                        [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'uops' }"
                        (click)="setSelected('uops')"
                        [matTooltip]="'uOps'"
                        *ngxPermissionsOnly="['uops']"
                    >
                        <img src="assets/images/sections/partner.png" alt="partners" matTooltipPosition="left" class="object-contain h-full w-10" />
                        <div class="text-lg font-semibold ml-2" *ngIf="selected === 'uops'">uOps</div>
                        <span class="absolute right-1 top-1 rounded-full bg-primary text-xs text-center text-white shadow w-4 h-4" *ngIf="(partners$ | async) && selected !== 'uops'">
                            {{ (partners$ | async)?.length }}
                        </span>
                    </button>
                </ng-container>
                <ng-container *ngIf="moduleEnabled$('threepl') | ngrxPush">
                    <button
                        class="flex items-center justify-center h-full w-16 relative p-4 bg-default hover:bg-primary-900 transition-all duration-300 ease-in-out"
                        [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'threepl' }"
                        (click)="setSelected('threepl')"
                        [matTooltip]="'Threepls'"
                        *ngxPermissionsOnly="['threepl']"
                    >
                        <img src="assets/images/sections/threepl.png" alt="threepls" matTooltipPosition="left" class="object-contain h-full h-full w-10" />
                        <div class="text-lg font-semibold ml-2" *ngIf="selected === 'threepl'">THREEPLs</div>
                        <span class="absolute right-1 top-1 rounded-full bg-primary text-xs text-center text-white shadow w-4 h-4" *ngIf="(threepls$ | async) && selected !== 'threepls'">
                            {{ (threepls$ | async)?.length }}
                        </span>
                    </button>
                </ng-container>

                <ng-container *ngIf="moduleEnabled$('ucontrol') | ngrxPush">
                    <ng-container *ngIf="directLinks; else noDirectLinks">
                        <a
                            class="flex items-center justify-center h-full w-16 relative p-4 bg-default hover:bg-primary-900 transition-all duration-300 ease-in-out"
                            [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'ucontrol' }"
                            [routerLink]="'/o/' + organisation.slug + '/ucontrol/dashboard'"
                            [matTooltip]="'UControl'"
                            (click)="setSelected('ucontrol')"
                            *ngxPermissionsOnly="['ucontrol']"
                        >
                            <!--                <img src="assets/images/sections/cockpit.png" alt="cockpit"-->
                            <!--                     matTooltipPosition="left" class="object-contain h-full">-->
                            <!--                        <mat-icon svgIcon="iconsmind:qr_code" class="text-green-500 icon-size-7"></mat-icon>-->
                            <img src="assets/images/sections/ucontrol.png" alt="ucontrol" matTooltipPosition="left" class="object-contain h-full w-10" />
                            <div class="text-lg font-semibold ml-2" *ngIf="selected === 'ucontrol'">UControl</div>
                        </a>
                    </ng-container>
                    <ng-template #noDirectLinks>
                        <button
                            class="flex items-center justify-center h-full w-16 relative p-4 bg-default hover:bg-primary-900 transition-all duration-300 ease-in-out"
                            [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'ucontrol' }"
                            [matTooltip]="'UControl'"
                            (click)="setSelected('ucontrol')"
                            *ngxPermissionsOnly="['ucontrol']"
                        >
                            <!--                <img src="assets/images/sections/cockpit.png" alt="cockpit"-->
                            <!--                     matTooltipPosition="left" class="object-contain h-full">-->
                            <!--                        <mat-icon svgIcon="iconsmind:qr_code" class="text-green-500 icon-size-7"></mat-icon>-->
                            <img src="assets/images/sections/ucontrol.png" alt="ucontrol" matTooltipPosition="left" class="object-contain h-full w-10" />
                            <div class="text-lg font-semibold ml-2" *ngIf="selected === 'ucontrol'">UControl</div>
                        </button>
                    </ng-template>
                </ng-container>

                <ng-container>
                    <ng-container *ngIf="directLinks; else noDirectLinks">
                        <a
                            class="flex items-center justify-center h-full w-16 relative p-4 bg-default hover:bg-primary-900 transition-all duration-300 ease-in-out"
                            [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'cockpit' }"
                            [routerLink]="'/o/' + organisation.slug + '/cockpit/dashboard'"
                            [matTooltip]="'Cockpit'"
                            (click)="setSelected('cockpit')"
                            *ngxPermissionsOnly="['cockpit']"
                        >
                            <img src="assets/images/sections/cockpit.png" alt="cockpit" matTooltipPosition="left" class="object-contain h-full w-10" />
                            <div class="text-lg font-semibold ml-2" *ngIf="selected === 'cockpit'">Cockpit</div>
                        </a>
                    </ng-container>
                    <ng-template #noDirectLinks>
                        <button
                            class="flex items-center justify-center h-full w-16 relative p-4 bg-default hover:bg-primary-900 transition-all duration-300 ease-in-out"
                            [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'cockpit' }"
                            (click)="setSelected('cockpit')"
                            *ngxPermissionsOnly="['cockpit']"
                        >
                            <img src="assets/images/sections/cockpit.png" alt="cockpit" matTooltipPosition="left" class="object-contain h-full w-10" />
                            <div class="text-lg font-semibold ml-2" *ngIf="selected === 'cockpit'">Cockpit</div>
                        </button>
                    </ng-template>
                </ng-container>

                <ng-container *ngIf="moduleEnabled$('book') | ngrxPush">
                    <button
                        class="flex h-full items-center justify-center relative bg-default hover:bg-primary-900 p-4 transition-all duration-300 ease-in-out"
                        [ngClass]="{ 'flex-grow bg-primary hover:bg-primary': selected === 'book' }"
                        (click)="setSelected('book')"
                        [matTooltip]="'NoteBook'"
                        *ngxPermissionsOnly="['book']"
                    >
                        <img src="assets/images/sections/partner.png" alt="partners" matTooltipPosition="left" class="object-contain h-full w-10" />
                        <div class="text-lg font-semibold ml-2" *ngIf="selected === 'book'">NoteBook</div>
                        <span class="absolute right-1 top-1 rounded-full bg-primary text-xs text-center text-white shadow w-4 h-4" *ngIf="(partners$ | async) && selected !== 'book'">
                            {{ (partners$ | async)?.length }}
                        </span>
                    </button>
                </ng-container>
            </div>

            <div class="flex h-full mt-16">
                <div class="flex flex-col flex-grow pt-4">
                    <div class="flex items-center mx-4 mb-4" *ngIf="selected === 'uops' || selected === 'book' || selected === 'threepl'">
                        <mat-form-field class="grow fuse-mat-no-subscript fuse-mat-emphasized-affix">
                            <input matInput [(ngModel)]="filter" [placeholder]="(selected | mapper : searchTitle) + ' search...'" />
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>

                        <button mat-icon-button (click)="exit.emit()" class="sm:hidden ml-4 border border-solid border-gray-400">
                            <mat-icon svgIcon="mat_outline:close"></mat-icon>
                        </button>
                    </div>

                    <mat-nav-list *ngIf="!selected || selected === 'uops' || selected === 'book'">
                        <a
                            class="sub-item px-6"
                            mat-list-item
                            *ngFor="let partner of filterArray(partners$ | async)"
                            [routerLink]="['/o', organisation.slug, selected || 'uops', partner.slug, selected === 'book' ? 'cover' : 'dashboard']"
                            (click)="exit.emit()"
                        >
                            <div class="flex justify-between w-full items-center">
                                <div class="flex flex-row justify-start items-center">
                                    <div class="w-10 h-10" [matTooltip]="partner.display_name">
                                        <img [src]="partner.icon_path" [alt]="partner.slug" />
                                    </div>
                                    <span class="ml-2">{{ partner.display_name }}</span>
                                </div>
                                <button mat-icon-button>
                                    <mat-icon>navigate_next</mat-icon>
                                </button>
                            </div>
                        </a>
                    </mat-nav-list>
                    <mat-divider *ngIf="!selected"></mat-divider>
                    <mat-nav-list *ngIf="!selected || selected === 'threepl'">
                        <a
                            class="sub-item px-6"
                            mat-list-item
                            *ngFor="let threepl of filterArray(threepls$ | async)"
                            [routerLink]="['/o', organisation.slug, '/threepl', threepl.slug, 'dashboard']"
                            (click)="exit.emit()"
                        >
                            <div class="flex justify-between w-full items-center">
                                <div class="flex flex-row justify-start items-center">
                                    <div class="w-10 h-10" [matTooltip]="threepl.display_name">
                                        <img src="assets/images/sections/single-threepl.png" [alt]="threepl.slug" />
                                    </div>
                                    <span class="ml-2">{{ threepl.display_name }}</span>
                                </div>
                                <button mat-icon-button>
                                    <mat-icon>navigate_next</mat-icon>
                                </button>
                            </div>
                        </a>
                    </mat-nav-list>
                    <div class="flex flex-col space-y-1" *ngIf="selected === 'ucontrol' || selected === 'cockpit'">
                        <ng-container *ngrxLet="checkedPartners$; let checkedPartners">
                            <div class="sub-item px-6 relative" *ngFor="let partner of checkedPartners" (click)="togglePartner(partner)">
                                <div class="flex justify-between w-full items-center">
                                    <div class="flex flex-row justify-start items-center">
                                        @if(selected === 'cockpit' && bubbleStatePerPartner(); as statePerPartner) {
                                        <enter-as-section-partner-state-indicator [partner]="partner" [state]="statePerPartner[partner.id]"></enter-as-section-partner-state-indicator>
                                        }

                                        <div class="w-10 h-10" [matTooltip]="partner.display_name">
                                            <img [src]="partner.icon_path" [alt]="partner.slug" />
                                        </div>
                                        <span class="ml-2">
                                            {{ partner.display_name }}
                                        </span>
                                    </div>
                                    <mat-slide-toggle [id]="partner.display_name" [checked]="partner.is_selected"></mat-slide-toggle>
                                </div>
                            </div>

                            <button class="mat-button" (click)="toggleAllPartners()">select/deselect all</button>

                            <fuse-alert [appearance]="'soft'" [type]="'warning'" [showIcon]="false" class="mt-2 mx-5" *ngIf="(selectedPartnersIds$ | ngrxPush).length === 0">
                                Select at least one partner to apply your changes
                            </fuse-alert>
                        </ng-container>
                        <div class="flex w-full items-center">
                            <ng-container *ngIf="directLinks; else noDirectLinks">
                                <button
                                    mat-flat-button
                                    [color]="'primary'"
                                    class="grow my-2 mx-5 w-full"
                                    [disabled]="!(hasChanges$ | ngrxPush) || (selectedPartnersIds$ | ngrxPush).length === 0"
                                    (click)="applyChanges()"
                                    *ngrxLet="submittingSelected$; let processing"
                                >
                                    <div class="flex items-center">
                                        <span class="mx-2">Apply changes</span>
                                        <mat-progress-spinner *ngIf="processing" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                                    </div>
                                </button>

                                <button mat-icon-button (click)="exit.emit()" class="sm:hidden mr-5 sm:mr-0 border border-solid border-gray-400">
                                    <mat-icon svgIcon="mat_outline:close"></mat-icon>
                                </button>
                            </ng-container>
                            <ng-template #noDirectLinks>
                                <button
                                    mat-flat-button
                                    [color]="'primary'"
                                    class="my-2 mx-5 flex w-full"
                                    [disabled]="(selectedPartnersIds$ | ngrxPush).length === 0"
                                    (click)="enter()"
                                    *ngrxLet="submittingSelected$; let processing"
                                >
                                    <div class="flex items-center">
                                        <span class="mx-2">Enter</span>
                                        <mat-progress-spinner *ngIf="processing" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
                                    </div>
                                </button>

                                <button mat-icon-button (click)="exit.emit()" class="sm:hidden mr-5 sm:mr-0 border border-solid border-gray-400">
                                    <mat-icon svgIcon="mat_outline:close"></mat-icon>
                                </button>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex mx-4 mb-2 justify-end space-x-2">
            <a mat-button class="bg-gray-200 text-secondary font-bold flex-grow" (click)="changeOrganisation()" *ngIf="(organisations$ | ngrxPush)?.length > 1">
                <span class="ml-1 mr-2">Change organisation</span>
                <mat-icon [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
            </a>
            <button mat-button class="flex items-center justify-center border border-primary border-solid border-gray-400 text-gray-400 font-bold" (click)="signOut()">
                <span>Sign out</span>
                <mat-icon [svgIcon]="'heroicons_outline:logout'" class="text-gray-400 ml-2"></mat-icon>
            </button>
        </div>
    </div>

    <button mat-icon-button (click)="exit.emit()" class="hidden sm:block sm:absolute sm:z-999 sm:-left-13 sm:top-4 mr-5 sm:mr-0 border border-solid border-gray-400">
        <mat-icon svgIcon="mat_outline:close"></mat-icon>
    </button>
</ng-container>
