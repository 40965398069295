<div *ngrxLet="loading$; let loading" class="flex flex-col">
    <mat-form-field class="fuse-mat-no-subscript fuse-mat-dense mb-2">
        <input matInput [formControl]="userFilter" placeholder="search..." />
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </mat-form-field>

    <div class="selection-list grow my-3 relative" *ngIf="data$ | ngrxPush as data">
        <div class="divide-y overflow-y-auto bg-card" *ngIf="data.length > 0" (scroll)="onScroll($event)">
            <mat-selection-list *ngIf="template">
                <mat-list-option *ngFor="let item of data" checkboxPosition="before" [value]="item" (selectedChange)="onOptionChanged(item, $event)" [selected]="item.selected">
                    <ng-container *ngTemplateOutlet="template; context: { item }"></ng-container>
                </mat-list-option>
            </mat-selection-list>
        </div>

        <div class="relative flex flex-col flex-auto divide-y overflow-y-auto bg-card p-4 items-center justify-center" *ngIf="data.length === 0 && !loading">
            <div class="flex flex-0 items-center justify-center w-8 h-8 rounded-full bg-primary-100">
                <mat-icon class="text-primary-500" [svgIcon]="'heroicons_outline:terminal'"></mat-icon>
            </div>
            <div class="mt-5 text-2xl font-semibold tracking-tight">No {{ model }}s found</div>
        </div>

        <div class="w-full mx-auto" *ngIf="loading">
            <div class="animate-pulse flex">
                <div class="flex-1 space-y-4 py-1">
                    <div class="h-4 bg-gray-400 rounded w-full"></div>
                </div>
            </div>
        </div>

        <div class="flex justify-end bg-card p-2 border-t border-solid border-gray-200">
            <span>Pages {{ page$ | async }} of {{ lastPage$ | async }}</span>
        </div>
    </div>
</div>

<ng-template #user let-user="item">
    <img
        class="w-12 h-12 rounded-full object-cover border-solid border-2 border-white shadow-lg"
        [src]="user.avatar || 'assets/images/avatar.png'"
        [matTooltip]="'User: ' + user.name"
        [alt]="user.name"
    />

    <div class="flex flex-col">
        <span>{{ user.name }}</span>
        <span class="text-secondary">{{ user.email }}</span>
    </div>
</ng-template>

<ng-template #team let-team="item">
    <div class="flex flex-col" [matTooltip]="team.description">
        <span>{{ team.name }}</span>
        <span class="text-secondary truncate">{{ team.description }}</span>
    </div>
</ng-template>

<ng-template #courier let-courier="item">
    <img
        class="w-12 h-12 rounded-full object-cover border-solid border-2 border-white shadow-lg"
        [src]="courier.icon_path || 'assets/images/avatar.png'"
        [matTooltip]="'Courier: ' + courier.display_name"
        [alt]="courier.display_name"
    />

    <div class="flex flex-col">
        <span>{{ courier.display_name }}</span>
        <span class="text-secondary">{{ courier.slug }}</span>
    </div>
</ng-template>

<ng-template #address let-address="item">
    <div class="flex flex-col" [matTooltip]="address.address">
        <span>{{ [address.address, address.address2].join(", ") }}</span>
        <span class="text-secondary truncate">{{ address.city }}, {{ address.zip }}, {{ address.country }}({{ address.country_iso_2 }})</span>
    </div>
</ng-template>

<ng-template #customer let-customer="item">
    <span>{{ customer.name }}</span>
</ng-template>

<ng-template #contact let-contact="item">
    <div class="flex flex-col">
        <span>{{ contact.first_name }} {{ contact.last_name }}</span>
        <span class="text-secondary truncate">{{ contact.email }} ({{ contact.phone }})</span>
    </div>
</ng-template>

<ng-template #device let-device="item">
    <div class="flex justify-between items-center">
        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500 min-w-36 device-ref">{{ device.ref }}</div>
            <span *ngIf="device.description" class="text-sm truncate text-gray-500 max-w-36" [matTooltip]="device.description">{{ device.description }}</span>
        </div>
        <app-device-preview class="flex flex-row justify-between w-full items-center w-1/3" [device]="device" [expanded]="false"></app-device-preview>
    </div>
</ng-template>
