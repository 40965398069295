import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserMenuModule } from 'app/layout/common/user-menu/user-menu.module';
import { SharedModule } from 'app/shared/shared.module';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TasksModule } from '../../../common/tasks/tasks.module';
import { NotificationsModule } from '../../../common/notifications/notifications.module';
import { NavigationModule } from '../../../navigation';
import { LogoModule } from '../../../common/organisation-selector/logo.module';
import { BookLayoutComponent } from './book.component';

@NgModule({
    declarations: [BookLayoutComponent],
    imports: [
        HttpClientModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        NavigationModule,
        NotificationsModule,
        TasksModule,
        SearchModule,
        ShortcutsModule,
        UserMenuModule,
        SharedModule,
        LetDirective,
        PushPipe,
        LogoModule
    ],
    exports: [BookLayoutComponent]
})
export class BookLayoutModule {}
