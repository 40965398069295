import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IPartner } from '@models/partner';
import { IThreepl } from '@models/threepl';
import { AuthDispatchers, AuthSelectors } from '@store/auth';
import { SettingsDispatchers, SettingsSelectors } from '@store/settings';
import { IOrganisation } from '@models/organisation';
import { combineLatestWith, map, take, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import * as SettingsActions from '@store/settings/settings.actions';
import { IBubbleStatePerPartner } from '@models/bubble';

@Component({
    selector: 'enter-as-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EnterAsSectionComponent implements OnInit, OnDestroy {
    @Input() directLinks = false;
    @Output() selectedChanged = new EventEmitter<string>();
    @Output() modifyOrganisation = new EventEmitter<void>();
    @Output() exit = new EventEmitter<void>();

    sidebarFolded: boolean;

    selected: string | null;
    bubbleStatePerPartner = signal<IBubbleStatePerPartner>(null);

    partners$: Observable<IPartner[]>;
    threepls$: Observable<IThreepl[]>;
    organisation$: Observable<IOrganisation>;
    organisations$: Observable<IOrganisation[]>;

    checkedPartners$: Observable<IPartner[]>;
    selectedPartnersIds$: Observable<number[]>;
    submittingSelected$: Observable<boolean>;

    filter: string = '';

    // Private
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _authSelectors: AuthSelectors,
        private _authDispatchers: AuthDispatchers,
        private _settingsSelectors: SettingsSelectors,
        private _settingsDispatchers: SettingsDispatchers,
        private router: Router,
        private actions$: Actions
    ) {
        this.organisations$ = _authSelectors.organisations$;
        this.partners$ = _settingsSelectors.allowedPartners$;
        this.checkedPartners$ = _settingsSelectors.checkedPartners$;
        this.organisation$ = _settingsSelectors.organisation$;
        this.threepls$ = _settingsSelectors.allowedThreepls$;
        this.selectedPartnersIds$ = _settingsSelectors.selectedPartnersIds$;
        this.submittingSelected$ = _settingsSelectors.submittingSelected$;

        // Set the defaults
        this.sidebarFolded = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    searchTitle = (section: 'uops' | 'threepl'): string => ({ uops: 'Partner', threepl: 'Threepl' }[section]);

    get hasChanges$(): Observable<boolean> {
        return this.partners$.pipe(
            withLatestFrom(this.selectedPartnersIds$),
            map(([partners, selectedPartnersIds]) =>
                partners.reduce(
                    (prev, curr) =>
                        prev ||
                        !(curr.is_selected
                            ? selectedPartnersIds.indexOf(curr.id) > -1
                            : selectedPartnersIds.indexOf(curr.id) === -1),
                    false
                )
            )
        );
    }

    togglePartner(partner: IPartner): void {
        this._settingsDispatchers.togglePartner(partner);
    }

    toggleAllPartners(): void {
        this._settingsDispatchers.toggleAllPartners();
    }

    filterArray(array: any[] | null): (IPartner | IThreepl)[] {
        return array
            ? array.filter(
                  item =>
                      item.display_name.toLowerCase().includes(this.filter.toLowerCase()) ||
                      item.slug.toLowerCase().includes(this.filter.toLowerCase())
              )
            : [];
    }

    moduleEnabled$(module): Observable<boolean> {
        return this.organisation$.pipe(map(o => o.enabled_modules.indexOf(module) > -1));
    }

    applyChanges(): void {
        this._settingsDispatchers.applySelectedPartnersChanges();
    }

    setSelected(s): void {
        this.selected = s;
        this.selectedChanged.next(s);
    }

    enter(): void {
        this.actions$
            .pipe(
                ofType(SettingsActions.applySelectedPartnersChangesSuccess),
                combineLatestWith(this.organisation$),
                take(1)
            )
            .subscribe(([, o]) => {
                this.router.navigate(['o', o.slug, this.selected, 'dashboard']);
            });

        this.applyChanges();

        //resolve page to go to
    }

    changeOrganisation(): void {
        if (this.router.url === '/entrance') {
            this.modifyOrganisation.emit(null);
        } else {
            this.router.navigate(['/entrance']);
        }
    }

    signOut(): void {
        this._authDispatchers.logout();
    }

    ngOnInit(): void {
        this.organisation$.pipe(take(1)).subscribe(o => (this.selected = o.enabled_modules[0] ?? null));
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
