<div class="relative" [class]="'version-' + version">
    <div class="relative min-h-100 flex justify-center">
        <div class="cube-container">
            <button mat-button class="mb-15 opacity-0"></button>
            <div id="cube" class="cube" [ngStyle]="{ transform }">
                <div class="cube-side"><span class="pl-18">left</span></div>
                <div class="cube-side">front</div>
                <div class="cube-side"><span class="pl-18">right</span></div>
                <div class="cube-side">back</div>
                <div class="cube-side" data-side="bottom"></div>
                <div class="cube-side" data-side="top"></div>
            </div>
        </div>
    </div>

    <div *ngIf="showDetails" class="flex-1 absolute top-1 right-0 w-40">
        <img src="/assets/images/other/accelerometer.svg" alt="accelerometer" class="object-contain max-w-36" />
    </div>

    <div *ngIf="showDetails" class="absolute right-0 top-40 w-40 ml-10 flex flex-col pt-2">
        <div class="font-bold text-slate-500 mb-1 text-center">
            Accelerometer
            <br />
            coordinates
        </div>
        <div class="flex flex-col text-left mb-15 pl-10">
            <span class="text-sm font-medium text-secondary">xAxis: {{ position[0] }}</span>
            <span class="text-sm font-medium text-secondary">yAxis: {{ position[1] }}</span>
            <span class="text-sm font-medium text-secondary">zAxis: {{ position[2] }}</span>
        </div>

        <div class="font-bold text-slate-500 mb-1 text-center">Pitch angle (deg)</div>
        <div class="flex items-center">
            <div class="flex flex-1 flex-col items-center">
                <span class="font-bold">x</span>
                <span class="text-sm font-medium text-secondary">{{ orientation.pitchX | number : "1.0-2" }}</span>
            </div>
            <div class="flex flex-1 flex-col items-center">
                <span class="font-bold">y</span>
                <span class="text-sm font-medium text-secondary">{{ orientation.pitchY | number : "1.0-2" }}</span>
            </div>
            <div class="flex flex-1 flex-col items-center">
                <span class="font-bold">z</span>
                <span class="text-sm font-medium text-secondary">{{ orientation.pitchZ | number : "1.0-2" }}</span>
            </div>
        </div>
    </div>
</div>

<div class="text-sm text-secondary mt-15">The accelerometer is a sensor that measures the acceleration of an object, detecting changes in its motion or orientation in space.</div>
