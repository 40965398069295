import { Identifiable } from '@core/types/model.types';

export const LinkMap = {
    device: 'ucontrol/devices',
    shipment: 'ucontrol/shipments',
    courier_tracking: 'ucontrol/tracking',
    order: 'uops/{partner_id}/order',
    bubble: 'cockpit/bubbles',
    story: 'book/{partner_slug}/chapters/{chapter}'
};

function makeUrlTo(target: Identifiable, params: Record<string, string | number> = {}): string {
    if (!(target._type in LinkMap)) {
        throw new Error('Unable to make URL to: ' + target._type ?? 'unknown');
    }

    let base = '/';

    const regex = /^\/o\/([^/]+)/;
    const match = location.pathname.match(regex);
    const name = match ? match[1] : '';

    if (name) {
        base = `/o/${name}/`;
    }

    let path: string = LinkMap[target._type];

    Object.entries(params).forEach(([key, value]) => {
        const placeholder = `{${key}}`;
        if (path.includes(placeholder)) {
            path = path.replace(placeholder, value.toString());
        }
    });

    if (path.includes('{partner_id}') && 'partner_id' in target) {
        const partnerId = target.partner_id;
        path = path.replace('{partner_id}', partnerId.toString());
    }

    return base + path + '/' + target.id;
}

function makeUrlToSection(section: string): string {
    let base = '/';

    const regex = /^\/o\/([^/]+)/;
    const match = location.pathname.match(regex);
    const name = match ? match[1] : '';

    if (name) {
        base = `/o/${name}/`;
    }

    return base + section + '/';
}

export const urlUtils = {
    makeUrlTo,
    makeUrlToSection
};
