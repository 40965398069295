import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { InitialData } from 'app/app.types';
import { NavigationService } from '@core/services/services/navigation.service';
import { AuthSelectors } from '@store/auth';
import { FuseNavigationService, NavigationItem } from 'app/layout/navigation';
import { SettingsSelectors } from '@store/settings';
import { PartnerSelectors } from 'app/core/store/partner';
import { AppCoreSelectors } from '@store/app-core';

@Component({
    selector: 'dense-layout',
    templateUrl: './dense.component.html',
    styleUrls: ['./dense.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DenseLayoutComponent implements OnInit, OnDestroy {
    data: InitialData;

    navigation: NavigationItem[] = [];
    isScreenSmall: boolean;
    navigationAppearance: 'default' | 'dense' = 'dense';

    user$ = this.authSelectors.user$;
    section$ = this.appCoreSelectors.type$;
    partner$ = this.partnerSelectors.partner$;
    organisation$ = this.settingsSelectors.organisation$;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _navigationService: NavigationService,
        private authSelectors: AuthSelectors,
        private settingsSelectors: SettingsSelectors,
        private appCoreSelectors: AppCoreSelectors,
        private partnerSelectors: PartnerSelectors
    ) {}

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to user changes
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: NavigationItem[]) => {
                this.navigation = navigation;

                // Mark for check
                // this._changeDetectorRef.markForCheck();
            });

        // Subscribe to the resolved route mock-api
        // todo: provide data for shortcuts/messages/notifications
        // this._activatedRoute.data.subscribe((data: Data) => {
        //     this.data = data.initialData;
        // });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent(name) as any;

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    toggleNavigationAppearance(): void {
        this.navigationAppearance = this.navigationAppearance === 'default' ? 'dense' : 'default';
    }
}
