import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ModalDialogService } from '@shared/components/modal-dialog/modal-dialog.service';
import { ICarbonFootprint, ICO2Trackable } from '@models/carbon-footprint';
import {
    CarbonFootprintFormComponent,
    CarbonFootprintFormData,
    CarbonFootprintFormOptions
} from '@modules/common/carbon_footprints/form/form.component';
import { UcontrolDevicesService } from '@core/services/resource/ucontrol/devices.resource';
import { ShipmentsService } from '@core/services/resource/ucontrol/shipments.resource';
import { TrackingService } from '@core/services/resource/ucontrol/tracking.service';
import { ICourierTracking } from '@models/courier-tracking';

interface CarbonFootprintCalculationService {
    action(id: number, action: string, data: any): any;
}

@Component({
    selector: 'app-co2e',
    standalone: true,
    imports: [SharedModule, CarbonFootprintFormComponent],
    templateUrl: './co2e.component.html'
})
export class Co2eComponent {
    @Input({ required: true }) trackable: ICO2Trackable;
    @Input() unknownText: string;

    @Output() calculated = new EventEmitter<ICarbonFootprint>();

    @ViewChild('detailsTemplate') private detailsTemplate: TemplateRef<any>;
    @ViewChild('detailsManualTemplate') private detailsManualTemplate: TemplateRef<any>;
    @ViewChild('carbonFootprintCalculationForm') carbonFootprintForm: TemplateRef<any>;
    @ViewChild('carbonFootprintFormToSetManually') carbonFootprintFormToSetManually: TemplateRef<any>;

    options: CarbonFootprintFormOptions;
    noticesShown = false;
    error: string;
    co2eValue: number;

    constructor(
        private modalDialogService: ModalDialogService,
        private devicesService: UcontrolDevicesService,
        private shipmentsService: ShipmentsService,
        private trackingService: TrackingService
    ) {}

    openDetails(): void {
        if (this.trackable.co2e_in_kg && !this.trackable.carbon_footprint) {
            this.modalDialogService.showDialog(this.detailsManualTemplate, {}, 'Carbon Footprint details');
            return;
        }

        this.modalDialogService.showDialog(this.detailsTemplate, {}, 'Carbon Footprint for route');
    }

    openCarbonFootprintForm(): void {
        if (this.trackable._type === 'courier_tracking') {
            this.configureFormForCourierTracking(this.trackable as ICourierTracking);
        }

        this.modalDialogService.showDialog(this.carbonFootprintForm, {}, 'Calculate carbon footprint');
    }

    openCarbonFootprintFormToSetManually(): void {
        this.modalDialogService.showDialog(this.carbonFootprintFormToSetManually, {}, 'Set carbon emissions manually');
    }

    syncCarbonFootprint(data: CarbonFootprintFormData): void {
        this.error = null;

        this.resolveService()
            .action(this.trackable.id, 'sync-carbon-footprint', data)
            .subscribe({
                next: response => {
                    this.trackable.carbon_footprint = response.carbon_footprint;
                    this.modalDialogService.closeDialog();
                    this.calculated.emit(response.carbon_footprint);
                    this.openDetails();
                },
                error: response => (this.error = response.error?.message)
            });
    }

    setCarbonFootprintManually(): void {
        this.resolveService()
            .action(this.trackable.id, 'set-carbon-footprint', {
                co2e_in_kg: this.co2eValue
            })
            .subscribe({
                next: response => {
                    this.trackable.co2e_in_kg = response.co2e_in_kg;
                    this.modalDialogService.closeDialog();
                },
                error: response => (this.error = response.error?.message)
            });
    }

    private configureFormForCourierTracking(tracking: ICourierTracking): void {
        this.options = {
            autoTransportMode: true,
            weightOptional:
                tracking.integrated_connection.connectable_type === 'shipsgo' || tracking.shipment_weight > 0
        };
    }

    private resolveService(): CarbonFootprintCalculationService {
        switch (this.trackable._type) {
            case 'shipment':
                return this.shipmentsService;
            case 'device':
                return this.devicesService;
            case 'courier_tracking':
                return this.trackingService;
            default:
                throw new Error('Unable to determinate service for carbon footprint calculation');
        }
    }
}
