@if(mode === 'default') {
<button mat-flat-button [ngSwitch]="loading()" (click)="emit()" class="bg-[#23BEC6] disabled:bg-gray-400 text-white disabled:text-white" type="button" [disabled]="form?.invalid">
    <span *ngSwitchDefault class="mx-4 text-white font-bold"><ng-container *ngTemplateOutlet="content"></ng-container></span>
    <mat-progress-spinner *ngSwitchCase="true" [diameter]="24" [mode]="'indeterminate'" class="text-white"></mat-progress-spinner>
</button>
} @else {
<button (click)="emit()" [ngSwitch]="loading()">
    <div *ngSwitchDefault><ng-container *ngTemplateOutlet="content"></ng-container></div>
    <mat-progress-spinner *ngSwitchCase="true" [diameter]="24" [mode]="'indeterminate'" class="text-white"></mat-progress-spinner>
</button>
}

<ng-template #content><ng-content></ng-content></ng-template>
