import { Subject } from 'rxjs';
import { EmbeddedViewRef, EventEmitter } from '@angular/core';
import { IAddress } from '@models/address';
import { DrawEvents, featureGroup, LatLng, LatLngBounds, marker, PointExpression, PolylineOptions } from 'leaflet';

import { IPort } from '@models/port';

/**
 * Describes how map info popup should be.
 * component: any angular component
 * initializer: callable that will set Inputs, etc...
 *
 * in future may contain extra options
 */
export class IMapInfoPopupDefinition {
    public initializer?: (component: unknown) => void;

    constructor(public readonly component: any, public readonly data?: Record<string, any>) {}

    setInitializer(initializer?: (component: unknown) => void): void {
        this.initializer = initializer;
    }
}

export type IMapPointInfoType = IMapInfoPopupDefinition | EmbeddedViewRef<any> | string;

export interface IMapCoordinates {
    lat: number;
    lng: number;
}

export interface IMapPolyLine extends IMapCoordinates {
    info?: IMapPointInfoType;
    areaRadius?: number;
    icon?: any;
    divIcon?: any;
    options?: PolylineOptions;
}

export interface IMapCircle extends IMapCoordinates {
    radius: number;
    style: { strokeColor: string; lineWidth: number; fillColor: string };
}

export interface IMapPolygon {
    coords: IMapCoordinates[];
    style: { strokeColor: string; lineWidth: number; fillColor: string };
}

export interface IMapRoute {
    from: IAddress | IPort;
    to: IAddress | IPort;
    config?: {
        hidePolyline?: boolean;
        legend?: string;
        color?: string;
        circleIcon?: boolean;
        hideMarkers?: boolean;
    };
}

export type polylineType = IMapPolyLine[];

export interface IMapIconOptions {
    iconSize?: PointExpression | undefined;
    iconAnchor?: PointExpression | undefined;
}

export interface IMapPoint {
    ref: string;
    address?: IAddress;
    position?: IMapCoordinates;
    data?: any;
    icon?: any;
    divIcon?: any;
    iconOptions?: IMapIconOptions;
    info?: IMapPointInfoType;
}

export interface IMotionLine {
    latLng: LatLng;
    traveledPath: Array<LatLng & { distanceToNextPoint: number }>;
    totalTraveledDistance?: number;
    fullLength?: number;
}
export interface IMotionOptions {
    options?: {
        color?: string;
        onTick?: (line: IMotionLine) => void;
        onComplete?: () => void;
    };
    motionOptions?: {
        auto?: boolean;
        duration?: number;
        speed?: number;
    };
    markerOptions?: {
        removeOnEnd?: boolean;
        showMarker?: boolean;
        icon?: { divIcon?: any; icon?: any; iconOptions?: IMapIconOptions };
    };
}
export interface IMotion extends IMotionOptions {
    path: IMapPolyLine[];
}

export interface IMapConfiguration {
    points?: IMapPoint[];
    routes?: IMapRoute[];
    polylines?: polylineType[];
    circles?: IMapCircle[];
    clusters?: IMapClustersConfiguration;
    legends?: IMapLegend[];
    polygons?: IMapPolygon[];
    motion?: IMotion | IMotion[];
}

export interface IMapClustersConfiguration {
    points?: { lat: number; lng: number; data: any }[];
    pointTemplate: string;
}

export interface IMapMessage {
    type: 'success' | 'error' | 'info' | 'warning';
    message: string;
}

export interface IMapLocationInfoComponent {
    coordinates: IMapPolyLine;
    action?: EventEmitter<{ type: string; data: any }>;
}

export interface IMapLegend {
    color: string;
    text: string;
    subtext?: string;
    icon?: string;
}

export class MapConfiguration {
    silent = false;

    buildMap: Subject<boolean> = new Subject<boolean>();
    markerClicked: Subject<any> = new Subject<any>();
    mapClicked: Subject<{ lat: number; lng: number }> = new Subject<{ lat: number; lng: number }>();
    boundingBox: Subject<LatLngBounds> = new Subject<LatLngBounds>();

    infoAction: Subject<{ type: string; data: any }> = new Subject<{ type: string; data: any }>();

    setBounds: Subject<LatLngBounds> = new Subject<LatLngBounds>();
    resetBounds: Subject<void> = new Subject<void>();

    startDrawing = new Subject<any>();
    cancelDrawing = new Subject<void>();
    drawingComplete = new Subject<DrawEvents.Created>();

    toggleMotion = new Subject<void>();

    messages: IMapMessage[] = [];

    configuration: IMapConfiguration;

    originalConfiguration: IMapConfiguration;

    static getBoundsByLatLng(lat: number, lng: number): LatLngBounds {
        return featureGroup([marker([lat, lng])]).getBounds();
    }

    previewConfiguration(configuration: IMapConfiguration): this {
        this.originalConfiguration ??= this.configuration;
        this.configuration = { ...this.configuration, ...configuration };
        return this;
    }

    restoreConfiguration(): this {
        if (this.originalConfiguration) {
            this.configuration = this.originalConfiguration;
            this.originalConfiguration = null;
        }

        return this;
    }

    updateConfiguration(configuration: IMapConfiguration): this {
        this.configuration = configuration;
        return this;
    }

    concatConfigurations(configurations: IMapConfiguration[]): this {
        const finalConfiguration: IMapConfiguration = {
            circles: [],
            polylines: [],
            points: [],
            routes: [],
            clusters: null,
            polygons: [],
            legends: [],
            motion: null
        };

        configurations.forEach((configuration: IMapConfiguration) => {
            finalConfiguration.circles = [...finalConfiguration.circles, ...(configuration.circles ?? [])];
            finalConfiguration.polylines = [...finalConfiguration.polylines, ...(configuration.polylines ?? [])];
            finalConfiguration.points = [...finalConfiguration.points, ...(configuration.points ?? [])];
            finalConfiguration.routes = [...finalConfiguration.routes, ...(configuration.routes ?? [])];
            finalConfiguration.polygons = [...finalConfiguration.polygons, ...(configuration.polygons ?? [])];
            finalConfiguration.legends = [...finalConfiguration.legends, ...(configuration.legends ?? [])];

            if (configuration.clusters) {
                finalConfiguration.clusters = configuration.clusters;
            }

            if (configuration.motion) {
                finalConfiguration.motion = configuration.motion;
            }
        });

        return this.updateConfiguration(finalConfiguration);
    }

    display(fitBounds = true): this {
        this.buildMap.next(fitBounds);
        this.messages = [];
        return this;
    }
}
