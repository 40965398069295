import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export type CarbonFootprintFormData = { transport_mode: string; weight: number; weight_unit: string };
export type CarbonFootprintFormOptions = { autoTransportMode?: boolean; weightOptional?: boolean };

@Component({
    selector: 'app-carbon-footprint-form',
    standalone: true,
    imports: [SharedModule],
    templateUrl: './form.component.html',
    styles: ''
})
export class CarbonFootprintFormComponent implements OnInit {
    @Input() defaults: Partial<CarbonFootprintFormData> = {};
    @Input() options: CarbonFootprintFormOptions;
    @Output() submitted = new EventEmitter<CarbonFootprintFormData>();

    transportModes = ['road', 'sea', 'air'];
    weightUnits = ['g', 'kg', 'lb', 't'];

    form = new FormGroup({
        transport_mode: new FormControl<string>('road', [Validators.required]),
        weight: new FormControl<number>(null, [Validators.required]),
        weight_unit: new FormControl<string>('kg', [Validators.required])
    });

    ngOnInit(): void {
        this.form.patchValue({ ...this.defaults, transport_mode: this.options?.autoTransportMode ? 'auto' : 'road' });

        if (this.options?.weightOptional) {
            this.form.controls.weight.removeValidators([Validators.required]);
        }
    }

    submit(): void {
        this.submitted.emit(this.form.getRawValue());
    }
}
