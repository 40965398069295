import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ExtendedListableResponseType } from '@core/types/http/listable-response.type';
import { IDevice } from '@models/device';
import { CountType } from '@core/types/http/count-response.type';

@Injectable({
    providedIn: 'root'
})
export class UcontrolDevicesService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadDevices(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ExtendedListableResponseType<IDevice, any> | CountType> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/devices', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadDevicesByIds(ids: number[], params: { [key: string]: any } = {}): Observable<IDevice[]> {
        return this.loadDevices({ id: ids, responseFormat: 'list', ...params }).pipe(
            map((response: ExtendedListableResponseType<IDevice, any> & { results: IDevice[] }) => response.results)
        );
    }

    loadDevice(device: number | string, params: { [key: string]: any } = {}): Observable<IDevice> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`ucontrol/devices/${device}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    createDevices(params: { [key: string]: any } = {}, data: any): Observable<any> {
        return this.httpService
            .post<any>(
                this.createUrlWithQueryParameters('ucontrol/devices', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                }),
                data
            )
            .pipe(map((response: any) => response));
    }

    updateDevice(deviceId: number, data: any): Observable<IDevice> {
        return this.httpService
            .put<any>(this.createUrl(`ucontrol/devices/${deviceId}`), data)
            .pipe(map((response: any) => response.data));
    }

    deleteDevice(deviceId: number): Observable<IDevice> {
        return this.httpService
            .delete<any>(this.createUrl(`ucontrol/devices/${deviceId}`))
            .pipe(map((response: any) => response.data));
    }

    refreshDevice(deviceId: number, data: any): Observable<IDevice> {
        return this.httpService
            .post<any>(this.createUrl(`ucontrol/devices/${deviceId}/refresh`), data)
            .pipe(map((response: any) => response.data));
    }

    action(deviceId: number, action: string, data = {}): Observable<any> {
        return this.httpService
            .post<any>(this.createUrl(`ucontrol/devices/${deviceId}/action/${action}`), data)
            .pipe(map((response: any) => response.data));
    }

    exportDevices(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/devices/export', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    exportDeviceEvents(deviceId: number, params: { [key: string]: any } = {}): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `ucontrol/devices/${deviceId}/export`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }
}
