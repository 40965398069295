import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    template: TemplateRef<any>;
    context: any;
    title?: string;
}

@Component({
    selector: 'app-modal-dialog-component',
    template: `
        <div class="flex flex-col md:min-w-160">
            <div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 dark text-on-primary">
                <div class="text-lg">{{ title }}</div>
                <button mat-icon-button (click)="matDialogRef.close()" [tabIndex]="-1">
                    <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
                </button>
            </div>

            <div class="p-6">
                <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="context"></ng-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDialogComponent {
    template: TemplateRef<any>;
    context: any;
    title?: string;

    constructor(
        public matDialogRef: MatDialogRef<ModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: DialogData
    ) {
        this.template = _data.template;
        this.context = _data.context;
        this.title = _data.title;
    }
}
