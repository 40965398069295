import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParams, ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ExtendedListableResponseType } from '@core/types/http/listable-response.type';

import { ICourierTracking, ICourierTrackingFacet } from '@models/courier-tracking';

@Injectable({
    providedIn: 'root'
})
export class TrackingService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadTrackings(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ExtendedListableResponseType<ICourierTracking, ICourierTrackingFacet>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/trackings', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadTracking(trackingId: number, params: { [key: string]: any } = {}): Observable<ICourierTracking> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`ucontrol/trackings/${trackingId}`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    createTracking(data: any, params: QueryParams = {}): Observable<ICourierTracking> {
        return this.httpService
            .post<any>(this.createUrl('ucontrol/trackings/', params), data)
            .pipe(map((response: any) => response.data));
    }

    updateTracking(
        trackingId: number,
        params: QueryParams,
        data: { [key: string]: any } = {}
    ): Observable<ICourierTracking> {
        return this.httpService
            .put<any>(this.createUrl(`ucontrol/trackings/${trackingId}`, params), data)
            .pipe(map((response: any) => response.data));
    }

    deleteTracking(trackingId: number): Observable<ICourierTracking> {
        return this.httpService
            .delete<any>(this.createUrl(`ucontrol/trackings/${trackingId}`))
            .pipe(map((response: any) => response.data));
    }

    exportTrackings(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/trackings/export', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    action(trackingId: number, action: string, data = {}): Observable<any> {
        return this.httpService
            .post<any>(this.createUrl(`ucontrol/trackings/${trackingId}/action/${action}`), data)
            .pipe(map((response: any) => response.data));
    }
}
