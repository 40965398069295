<button [matMenuTriggerFor]="menu" class="flex gap-1 items-center font-semibold text-default cursor-pointer relative">
    <mat-icon svgIcon="logivice:co2" [matTooltip]="'The CO2 emission'" [matTooltipPosition]="'before'"></mat-icon>

    @if (trackable.co2e_in_kg) {
    <span class="text-md whitespace-nowrap">{{ trackable.co2e_in_kg | number : "1.0-3" }} kg</span>
    } @else if (trackable.carbon_footprint) {
    <span class="text-md whitespace-nowrap">
        {{ trackable.carbon_footprint.carbon_emission | number : "1.0-3" }}
        {{ trackable.carbon_footprint.carbon_emission_unit }}
    </span>
    } @else if(unknownText) {
    <span class="text-secondary text-sm">{{ unknownText }}</span>
    }

    <!-- manually notice -->
    @if (trackable.co2e_in_kg && !trackable.carbon_footprint) {
    <mat-icon svgIcon="logivice:exclamation_mark" class="icon-size-3 absolute -right-5" [matTooltip]="'Was filled manually'"></mat-icon>
    }
</button>
<mat-menu #menu="matMenu">
    @if (trackable.carbon_footprint || trackable.co2e_in_kg) {
    <button mat-menu-item (click)="openDetails()">Show details</button>
    }

    <button mat-menu-item (click)="openCarbonFootprintForm()">Calculate</button>

    @if (trackable._type === 'courier_tracking') {
    <button mat-menu-item (click)="openCarbonFootprintFormToSetManually()">Set</button>
    }
</mat-menu>

<ng-template #carbonFootprintCalculationForm>
    <app-carbon-footprint-form [options]="options" (submitted)="syncCarbonFootprint($event)"></app-carbon-footprint-form>
    <div *ngIf="error" class="text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600 mx-5 p-2">
        <div class="max-w-100">{{ error }}</div>
    </div>
    <div class="absolute right-5 bottom-5 flex items-center gap-2">
        <span class="whitespace-nowrap">Powered by</span>
        <img src="/assets/images/integrations/climatiq.webp" alt="climatiq_logo" class="h-8" />
    </div>
</ng-template>

<ng-template #carbonFootprintFormToSetManually>
    <div class="flex items-center justify-center gap-4">
        <mat-form-field>
            <input matInput type="number" [(ngModel)]="co2eValue" />
            <span matSuffix class="px-5">in kg</span>
        </mat-form-field>

        <action-button (action)="setCarbonFootprintManually()">Save</action-button>
    </div>
</ng-template>

<ng-template #detailsTemplate>
    <div class="px-4" *ngIf="trackable.carbon_footprint as data">
        <div class="flex justify-between">
            <span class="flex items-center space-x-2 mb-4">
                <mat-icon svgIcon="logivice:co2"></mat-icon>
                <span>The {{ data.is_estimate ? "estimated" : "actual" }} CO2 emission is</span>
                <span class="font-bold">{{ data.carbon_emission | number : "1.0-3" }} {{ data.carbon_emission_unit }}</span>
            </span>

            <mat-slide-toggle *ngIf="data.notices?.length" class="no-ripple" [(ngModel)]="noticesShown">Show notices</mat-slide-toggle>
        </div>

        @if (noticesShown) {
        <div class="max-w-120">
            <div class="mt-2 font-bold text-yellow-500">Notices:</div>
            <div class="py-2" [class.text-warn-500]="notice.severity === 'critical'" *ngFor="let notice of data.notices; let i = index">
                <span *ngIf="data.route.length > 3" class="mr-2 font-semibold">leg {{ notice.leg_index + 1 }}:</span>
                <span>{{ notice.message }}</span>
            </div>
        </div>
        }

        <div class="flex flex-col divide-y divide-solid divide-gray-300">
            <div class="flex flex-row space-x-4 py-2 relative" *ngFor="let item of data.route">
                <span class="font-bold w-15 min-w-15">{{ item.type | ucfirst }}</span>
                @if (item.type === 'location') {
                <span>{{ item.name }}</span>
                } @else {
                <div class="flex flex-col">
                    <span>Emissions: {{ item.co2e }} {{ item.co2e_unit }}</span>
                    <span>Transport mode: {{ item.transport_mode }}</span>
                    <span>Distance: {{ item.distance_km }} km</span>
                </div>
                <mat-icon class="absolute right-5 top-3 opacity-20 icon-size-12 rotate-6" [svgIcon]="item.transport_mode | icon : 'transport_mode'"></mat-icon>
                }
            </div>
        </div>

        <div class="flex flex-col gap-2 font-bold mt-4">
            <span>Weight: {{ data.cargo_weight }} {{ data.cargo_weight_unit }}</span>
            <span>Total distance: {{ data.distance_km }} km</span>
        </div>
    </div>
</ng-template>

<ng-template #detailsManualTemplate>
    <span class="flex items-center space-x-2 mb-4">
        <mat-icon svgIcon="logivice:co2"></mat-icon>
        <span>CO2 emission is</span>
        <span class="font-bold">{{ trackable.co2e_in_kg | number : "1.0-3" }} kg</span>
    </span>
    <span>Carbon emissions amount was filled manually!</span>
</ng-template>
