import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SoundService {
    audio = new Audio();

    constructor() {}

    notification(file: string = 'notification.wav'): void {
        this.audio.src = '../../../../assets/sounds/' + file;
        this.audio.load();
        this.audio.play();
    }
}
