import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { IOrder } from '@models/order';
import { SuccessResponseType } from '@core/types/http/respone.type';
import { IServiceLevel } from '@models/service-level';
import { ICourierService } from '@models/transaction';
import { IRemark } from '@models/order-type';
import { IOrderItem } from '@models/order-item';
import { IInventoryAllocation, IWarehouseOrder } from '@models/warehouse';
import { IAddress } from '@models/address';
import { IHoliday } from '@models/holiday';
import { ITimeline } from '@models/timeline';
import { IPagination } from '@models/pagination';
import { ExtendedListableResponseType, ListableResponseType } from '@core/types/http/listable-response.type';
import { IMapRoute } from '@shared/components/leaflet-map/map-configuration';
import { IShipment } from '@models/shipment';
import { IWarehouseTransaction } from '@models/warehouse-transaction';

@Injectable({
    providedIn: 'root'
})
export class PartnerOrdersService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadOrders(
        partner: string | number,
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ExtendedListableResponseType<IOrder, any>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(`partner/${partner}/orders`, (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }

    loadOrderItems(
        partner: string,
        orderId: number,
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<ListableResponseType<IOrderItem>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }

                        for (const filter of filters) {
                            qs.push(filter.param, filter.value, filter.operator);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    loadOrderItemRelations(
        relation: 'allocations' | 'transactions',
        partner: string,
        orderId: number,
        itemId: number,
        params: { [key: string]: any } = {}
    ): Observable<ListableResponseType<IWarehouseTransaction | IInventoryAllocation>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/${itemId}/${relation}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    orderItemUnlinkTransactions(
        partner: string,
        orderId: number,
        itemId: number,
        transactions: number[],
        all = false
    ): Observable<any> {
        return this.httpService
            .delete<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/${itemId}/transactions`,
                    (qs: QueryStringParameters) => {
                        qs.push('transactions_id', transactions);
                        qs.push('all', all);
                    }
                )
            )
            .pipe(map((response: any) => response));
    }

    orderItemRemoveAllocations(
        partner: string,
        orderId: number,
        itemId: number,
        allocations: number[],
        all = false
    ): Observable<any> {
        return this.httpService
            .delete<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/${itemId}/allocations`,
                    (qs: QueryStringParameters) => {
                        qs.push('allocations_id', allocations);
                        qs.push('all', all);
                    }
                )
            )
            .pipe(map((response: any) => response));
    }

    loadOrder(partner: string, orderId: number, params: { [key: string]: any } = {}): Observable<IOrder> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<IOrder>) => response.data));
    }

    orderRemarks(
        partner: string,
        orderId: number,
        params: { [key: string]: any } = {}
    ): Observable<IPagination<IRemark>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/remarks`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    createRemark(partner: any, orderId: number, data: any): Observable<IRemark> {
        return this.httpService
            .post<any>(this.createUrl(`partner/${partner}/orders/${orderId}/remarks`), data)
            .pipe(map((response: SuccessResponseType<IRemark>) => response.data));
    }

    orderAttachments(
        partner: string,
        orderId: number,
        params: { [key: string]: any } = {}
    ): Observable<IPagination<any>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/attachments`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    createOrder(partner: string, data: { [key: string]: any }): Observable<IOrder> {
        return this.httpService
            .post<any>(this.createUrl(`partner/${partner}/orders`), data)
            .pipe(map((response: SuccessResponseType<IOrder>) => response.data));
    }

    updateOrder(partner: string, orderId: number, data: { [key: string]: any }): Observable<IOrder> {
        return this.httpService
            .put<any>(this.createUrl(`partner/${partner}/orders/${orderId}`), data)
            .pipe(map((response: SuccessResponseType<IOrder>) => response.data));
    }

    addOrderItem(partner: string, orderId: number, data: { [key: string]: any }): Observable<IOrderItem> {
        return this.httpService
            .post<any>(this.createUrl(`partner/${partner}/orders/${orderId}/items`), data)
            .pipe(map((response: SuccessResponseType<IOrderItem>) => response.data));
    }

    removeOrderItem(partner: string, orderId: number, orderItemId: number): Observable<IOrderItem> {
        return this.httpService
            .delete<any>(this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}`))
            .pipe(map((response: SuccessResponseType<IOrderItem>) => response.data));
    }

    loadOrderItem(
        partner: string,
        orderId: number,
        orderItemId: number,
        params: { [key: string]: any } = {}
    ): Observable<IOrderItem> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/${orderItemId}}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<IOrderItem>) => response.data));
    }

    updateOrderItem(
        partner: string,
        orderId: number,
        orderItemId: number,
        data: { [key: string]: any }
    ): Observable<IOrderItem> {
        return this.httpService
            .put<any>(this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}`), data)
            .pipe(map((response: SuccessResponseType<IOrderItem>) => response.data));
    }

    addOrderItemInventory(
        partner: string,
        orderId: number,
        orderItemId: number,
        data: {
            [key: string]: any;
        }
    ): Observable<any> {
        return this.httpService
            .post<any>(this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}/add-inventory`), data)
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    orderItemAction(
        partner: string,
        orderId: number,
        orderItemId: number,
        action: string,
        data: {
            [key: string]: any;
        }
    ): Observable<any> {
        return this.httpService
            .post<any>(
                this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}/action/${action}`),
                data
            )
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    allocateOrderItemInventory(
        partner: string,
        orderId: number,
        orderItemId: number,
        data: {
            [key: string]: any;
        }
    ): Observable<any> {
        return this.httpService
            .post<any>(
                this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}/allocate-inventory`),
                data
            )
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    removeOrderItemInventory(
        partner: string,
        orderId: number,
        orderItemId: number,
        data: {
            [key: string]: any;
        }
    ): Observable<any> {
        return this.httpService
            .post<any>(
                this.createUrl(`partner/${partner}/orders/${orderId}/items/${orderItemId}/remove-inventory`),
                data
            )
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    updateOrderTemplate(partner: string, data: { [key: string]: any }): Observable<any> {
        return this.httpService
            .put<any>(this.createUrl(`partner/${partner}/orders/template`), data)
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    //TODO: deprecated (refactored to actions)
    loadHelperData(
        partner: string | number,
        orderId: number,
        type,
        params: { [key: string]: any } = {}
    ): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/helper/${type}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    action(
        partner: string | number,
        orderId: number,
        action: string,
        data = {},
        method: 'post' | 'get' = 'post'
    ): Observable<any> {
        return this.httpService[method]<any>(
            this.createUrl(`partner/${partner}/orders/${orderId}/action/${action}`),
            data
        ).pipe(map((response: any) => response.data));
    }

    serviceLevels(partner: string, orderId: number): Observable<IServiceLevel[]> {
        return this.loadHelperData(partner, orderId, 'get-courier-services');
    }

    courierServices(partner: string, orderId: number): Observable<ICourierService[]> {
        return this.loadHelperData(partner, orderId, 'get-service-levels');
    }

    loadHolidays(partner: string, orderId: number): Observable<IHoliday[]> {
        return this.action(partner, orderId, 'get-holidays', {}, 'get');
    }

    loadAddresses(partner: string, orderId: number): Observable<IMapRoute[]> {
        return this.loadHelperData(partner, orderId, 'get-addresses');
    }

    loadAddressesOptions(
        partner: string,
        orderId: number,
        type: string
    ): Observable<{ from_address: IAddress[]; to_address: IAddress[] }> {
        return this.loadHelperData(partner, orderId, 'get-shipment-addresses', { type });
    }

    loadTimeline(partner: string, orderId: number): Observable<ITimeline> {
        return this.loadHelperData(partner, orderId, 'get-timeline');
    }

    checkRefUniquity(partner: string, ref: string): Observable<boolean> {
        return this.action(partner, 0, 'check-ref-uniquity', { ref });
    }

    getUniqueRef(partner: string): Observable<string> {
        return this.action(partner, 0, 'get-unique-ref', {}, 'get');
    }

    loadShipments(partner: string, orderId: number, params: { [key: string]: any } = {}): Observable<IShipment[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/shipments`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<IShipment[]>) => response.data));
    }

    loadWarehouseOrders(
        partner: string,
        orderId: number,
        params: { [key: string]: any } = {}
    ): Observable<IWarehouseOrder[]> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/warehouse-orders`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<IWarehouseOrder[]>) => response.data));
    }

    exportItems(partner: string, orderId: number, params: { [key: string]: any } = {}): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/export`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    exportOrderItemRelations(
        relation: 'allocations' | 'transactions',
        partner: string,
        orderId: number,
        itemId: number,
        params: { [key: string]: any } = {}
    ): Observable<any> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/items/${itemId}/${relation}/export`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: any) => response.data));
    }

    loadOrderShipment(
        partner: string | number,
        orderId: number,
        shipmentId: number,
        params: { [key: string]: any } = {}
    ): Observable<IShipment> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters(
                    `partner/${partner}/orders/${orderId}/shipments/${shipmentId}`,
                    (qs: QueryStringParameters) => {
                        for (const key of Object.keys(params)) {
                            qs.push(key, params[key]);
                        }
                    }
                )
            )
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }

    updateOrderShipment(
        partner: string | number,
        orderId: number,
        shipmentId: number,
        data: {
            [key: string]: any;
        }
    ): Observable<IShipment> {
        return this.httpService
            .put<any>(this.createUrl(`partner/${partner}/orders/${orderId}/shipments/${shipmentId}`), data)
            .pipe(map((response: SuccessResponseType<any>) => response.data));
    }
}
