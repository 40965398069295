<form [formGroup]="form" class="flex flex-col gap-6 p-5">
    <mat-form-field>
        <mat-label>Transport mode</mat-label>
        <mat-select formControlName="transport_mode">
            <mat-option *ngIf="options?.autoTransportMode" [value]="'auto'">Auto</mat-option>
            <mat-option *ngFor="let mode of transportModes" [value]="mode">{{ mode | ucfirst }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>
            <span>Weight</span>

            @if (options?.weightOptional) {
            <span class="text-secondary ml-4">(optional) will be resolved automatically, otherwise fill</span>
            }
        </mat-label>
        <input matInput type="number" formControlName="weight" />
        <mat-select formControlName="weight_unit" matSuffix>
            <mat-option *ngFor="let unit of weightUnits" [value]="unit">{{ unit }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="flex items-center">
        <action-button [form]="form" (action)="submit()">Calculate</action-button>
    </div>
</form>
