import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import moment from 'moment';
import { IUser } from '@models/user';
import { ITeam } from '@models/team';
import { RequestCreateStore } from './create.store';
import { take } from 'rxjs/operators';
import { filterByPriorityOptions } from '../list/filters/filter-options';
import { AuthSelectors } from '@store/auth';
import { get, pick } from 'lodash-es';
import { RequestService } from '@core/services/resource/request.service';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { AppCoreSelectors } from '@store/app-core';
import { PartnerSelectors } from '../../../../core/store/partner';
import { FileService } from '@core/services/resource/file.service';

@Component({
    selector: 'app-request-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [RequestCreateStore]
})
export class RequestCreateComponent implements OnInit, OnDestroy {
    @ViewChild('createRequestOrigin') private _createRequestOrigin: MatButton;
    @ViewChild('createRequestPanel') private _createRequestPanel: TemplateRef<any>;

    assignee: IUser;
    assigneeChange: Subject<IUser> = new Subject();

    team: ITeam;
    teamChange: Subject<ITeam> = new Subject();

    requestForm: FormGroup;
    timelineInput: FormControl = new FormControl({ start: moment().startOf('day'), end: moment().endOf('day') });

    filterByPriorityOptions = filterByPriorityOptions.filter(p => p.title !== 'All');
    filterByPriorityMenuOpened = false;

    attachments: any[] = [];

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private _formBuilder: FormBuilder,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        public requestCreateStore: RequestCreateStore,
        private authSelectors: AuthSelectors,
        private requestService: RequestService,
        private snackbarService: SnackbarService,
        private coreSelectors: AppCoreSelectors,
        private partnerSelectors: PartnerSelectors,
        private fileService: FileService
    ) {
        this.assigneeChange.subscribe(u => {
            this.assignee = u;
            this.requestForm.get('assignee_id').setValue(u.id);
        });

        this.teamChange.subscribe(t => {
            this.team = t;
            this.requestForm.get('team_id').setValue(t.id);
        });
    }

    get attached(): boolean {
        return this._overlayRef ? this._overlayRef.hasAttached() : false;
    }

    init(): void {
        this.requestCreateStore.requestKinds$.pipe(take(1)).subscribe(rk => {
            if (!rk || rk.length === 0) {
                this.requestCreateStore.loadRequestKinds();
            }
        });
    }

    getBgClassByPriority(priority): string {
        return this.filterByPriorityOptions[this.filterByPriorityOptions.findIndex(f => f.title === priority)].bg;
    }

    getIconByPriority(priority): string {
        return this.filterByPriorityOptions[this.filterByPriorityOptions.findIndex(f => f.title === priority)].icon;
    }

    ngOnInit(): void {
        this._buildForm();

        // Subscribe to 'range' field value changes
        this.timelineInput.valueChanges.subscribe(value => {
            if (!value) {
                return;
            }

            // Set the 'start' field value from the range
            this.requestForm
                .get('timeline_from')
                .setValue(moment(value.start).format('YYYY-MM-DD HH:mm:ss'), { emitEvent: false });

            this.requestForm
                .get('timeline_to')
                .setValue(moment(value.end).format('YYYY-MM-DD HH:mm:ss'), { emitEvent: false });
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    async submit(): Promise<void> {
        const data = this.requestForm.value;

        const metainfo = await this.coreSelectors.metainfo$.pipe(take(1)).toPromise();

        if (metainfo.type === 'uops') {
            data.partner_id = (await this.partnerSelectors.partner$.pipe(take(1)).toPromise()).id;
        }

        data.order_id = get(this.router.url.match(/\/orders\/(\d+)+[\/]?/), 1, null);

        data.attachments = this.attachments;

        const request = await this.requestService.createRequest(data).toPromise();
        this.snackbarService.show('success', 'Request created');
        this._buildForm();
        this.closePanel();
    }

    togglePanel(): void {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this.closePanel();
        } else {
            this.openPanel();
        }
    }

    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._createRequestPanel || !this._createRequestOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._createRequestPanel, this._viewContainerRef));

        this.init();
    }

    assignToMe(): void {
        this.authSelectors.user$
            .pipe(take(1))
            .subscribe(u => this.assigneeChange.next(pick(u, ['id', 'email', 'name', 'avatar']) as IUser));
    }

    uploadAttachment(event): void {
        const file: File = event.target.files[0];

        if (file) {
            this.fileService
                .uploadShort(file)
                .subscribe(uploaded => this.attachments.push({ url: uploaded, name: file.name }));
        }
    }

    deleteAttachment(attachment): void {
        this.attachments.splice(
            this.attachments.findIndex(a => a.url === attachment.url),
            1
        );
    }

    // downloadAttachment(attachment): void {
    //     this.fileService.downloadFile(attachment.path).subscribe(
    //         (response: HttpResponse<Blob>) => {
    //             const filename = response.headers.get('content-disposition').split(';')[1].split('=')[1].replace(/\"/g, '');
    //             const blob = response.body;
    //             saveAs(blob, filename);
    //         }
    //     );
    // }

    closePanel(): void {
        this._overlayRef.detach();
    }

    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(this._createRequestOrigin._elementRef.nativeElement)
                .withLockedPosition()
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    private _buildForm(): void {
        this.requestForm = this._formBuilder.group({
            title: [null, [Validators.required]],
            description: [null, Validators.required],
            assignee_id: [null, Validators.required],
            team_id: [null],
            inform_team: [false],
            order_remark_type_id: [null],
            request_kind_id: [null, Validators.required],
            priority: ['Normal', Validators.required],
            timeline_from: [moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'), Validators.required],
            timeline_to: [moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'), Validators.required]
        });
    }
}
