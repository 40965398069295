import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';

@Injectable()
export class ModalDialogService {
    constructor(private _matDialog: MatDialog) {}

    showDialog(template: TemplateRef<any>, context: any = {}, title?: string): void {
        this._matDialog.open(ModalDialogComponent, {
            data: { template, context, title }
        });
    }

    closeDialog(): void {
        this._matDialog.closeAll();
    }
}
