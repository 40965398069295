import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MATERIAL_MODULES } from '@shared/material';
import { SHARED_DIRECTIVES } from '@shared/directives';
import { SHARED_PIPES } from '@shared/pipes';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TimeToTextPipe } from './pipes/time-to-text.pipe';
import { ColoredDirective } from './directives/colored.directive';
import { MapperPipe } from './pipes/mapper.pipe';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { TemperaturePipe } from './pipes/measurement/temperature.pipe';
import { DistancePipe } from './pipes/measurement/distance.pipe';
import { IconPipe } from './pipes/icon.pipe';
import { RequestPulseComponent } from '@shared/components/request-pulse/request-pulse.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { CubeVisualizationComponent } from './components/cube-visualization/cube-visualization.component';
import { SimpleTableComponent } from '@shared/components/simple-table/simple-table.component';
import { RouterLink } from '@angular/router';

// todo: delete material module , and connect only needed modules for each module instead - (performance improving?)
@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxPermissionsModule, ...MATERIAL_MODULES, RouterLink],
    declarations: [
        ...SHARED_PIPES,
        ...SHARED_DIRECTIVES,
        TimeToTextPipe,
        ColoredDirective,
        MapperPipe,
        NumberOnlyDirective,
        TemperaturePipe,
        DistancePipe,
        IconPipe,
        RequestPulseComponent,
        ActionButtonComponent,
        CubeVisualizationComponent,
        SimpleTableComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        ...MATERIAL_MODULES,
        ...SHARED_DIRECTIVES,
        ...SHARED_PIPES,
        TimeToTextPipe,
        ColoredDirective,
        MapperPipe,
        NumberOnlyDirective,
        TemperaturePipe,
        DistancePipe,
        IconPipe,
        RequestPulseComponent,
        ActionButtonComponent,
        CubeVisualizationComponent,
        SimpleTableComponent
    ]
})
export class SharedModule {}
