import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { IPointOfInterest, IPointOfInterestTarget } from '@models/point-of-interest';
import { IPagination } from '@models/pagination';

@Injectable({
    providedIn: 'root'
})
export class PointsOfInterestService extends ResourceService {
    constructor(protected httpService: ApiHttpService) {
        super();
    }

    createPointOfInterest(data?: any): Observable<IPointOfInterest> {
        return this.httpService.post(this.createUrl('ucontrol/poi'), data).pipe(map((response: any) => response.data));
    }

    updatePointOfInterest(pointOfInterestId: number, data?: any): Observable<IPointOfInterest> {
        return this.httpService
            .put(this.createUrl(`ucontrol/poi/${pointOfInterestId}`), data)
            .pipe(map((response: any) => response.data));
    }

    deletePointOfInterest(pointOfInterestId: number): Observable<IPointOfInterest> {
        return this.httpService
            .delete(this.createUrl(`ucontrol/poi/${pointOfInterestId}`))
            .pipe(map((response: any) => response.data));
    }

    loadPOITargets(
        params: { [key: string]: any } = {},
        filters: { param: string; operator: string; value: any }[] = []
    ): Observable<IPagination<IPointOfInterestTarget>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('ucontrol/poi/targets', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }

                    for (const filter of filters) {
                        qs.push(filter.param, filter.value, filter.operator);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
