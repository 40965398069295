/* eslint-disable */
export const iconMapping = {
    transport_mode: {
        road: 'logivice:courier_track',
        sea: 'logivice:ocean_ship',
        air: 'logivice:freight_plane',
    },
    shipping_mode: {
        courier: 'logivice:courier_track',
        seafreight: 'logivice:ocean_ship',
        airfreight: 'logivice:freight_plane',
    },
    vehicle_type: {
        car: 'mat_outline:directions_car',
        plain: 'mat_outline:flight_takeoff',
        ship: 'mat_outline:directions_boat',
    },
    courier_source: {
        aftership: 'logivice:courier_track',
        shipsgo: 'logivice:ocean_ship',
        champ: 'logivice:freight_plane',
        flight: 'logivice:freight_plane',
    },
    courier_source_pin: {
        aftership: 'logivice:courier_track_pin',
        shipsgo: 'logivice:ocean_ship_pin',
        champ: 'logivice:freight_plane_pin',
        flight: 'logivice:freight_plane_pin',
    },
    shipment_status: {
        'created': 'mat_outline:info',
        'shipped': 'mat_outline:local_shipping',
        'partially-shipped': 'mat_outline:schedule_send',
        'delayed': 'mat_outline:warning_amber',
        'delivered': 'mat_outline:done_all',
        'partially-delivered': 'mat_outline:done',
        'partially-arrived': 'mat_outline:done',
    },
    item_batch: {
        serial: 'mat_outline:ballot',
        box: 'feather:package',
        pallet: 'logivice:pallet'
    },
    subject: {
        order: 'heroicons_outline:clipboard-li',
        shipment: 'heroicons_outline:cube',
        device: 'heroicons_outline:chip',
        courier_tracking: 'mat_outline:share_location',
    },
    device_event: {
        "initial": "mat_solid:power_settings_new",
        "temperature_changed": "logivice:temp_medium",
        "location_changed": "logivice:location_changed",
        "seal_is_open": "logivice:box_opened",
        "had_shock": "iconsmind:pulse",
        "movement": "logivice:movement",
        "flipped": "box_flipped"
    }
};
