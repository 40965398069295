import { Component, input } from '@angular/core';

@Component({
    selector: 'app-simple-table',
    templateUrl: './simple-table.component.html'
})
export class SimpleTableComponent {
    /** columns <name, type>  type string,datetime,link,etc */
    columns = input.required<Record<string, string>>();
    rows = input.required<Array<Record<string, any>>>();
}
