<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
    <button mat-icon-button *ngIf="!opened" (click)="open()">
        <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
    </button>
    <div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
        <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input
            class="w-full h-full px-16 sm:px-18"
            [formControl]="searchControl"
            [matAutocomplete]="matAutocomplete"
            [placeholder]="'Search...'"
            (keydown)="onKeydown($event)"
            #barSearchInput
        />
        <mat-autocomplete class="max-h-128 sm:px-2 border-t rounded-b shadow-md" [autoSelectActiveOption]="true" [disableRipple]="true" #matAutocomplete="matAutocomplete">
            <mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent" *ngIf="resultSets && !resultSets.length">No results found!</mat-option>
            @for(resultSet of resultSets; track trackByFn){
            <mat-optgroup class="flex items-center mt-2 px-2">
                <span class="text-sm font-semibold tracking-wider text-secondary">{{ resultSet.label.toUpperCase() }}</span>
            </mat-optgroup>
            @for(result of resultSet.results; track trackByFn) {
            <mat-option
                class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
                [routerLink]="result.link | urlTo"
                [value]="result.value"
                (click)="$event.stopPropagation(); close()"
            >
                <div class="flex flex-col py-2 gap-4">
                    <ng-container *ngTemplateOutlet="defaultResult; context: { $implicit: result }"></ng-container>

                    @if(resultSet.id === 'devices'){
                    <ng-container *ngTemplateOutlet="deviceResult; context: { $implicit: result }"></ng-container>
                    } @else if (resultSet.id === 'shipments') {
                    <ng-container *ngTemplateOutlet="shipmentResult; context: { $implicit: result }"></ng-container>
                    }@else if (resultSet.id === 'courier_trackings') {
                    <ng-container *ngTemplateOutlet="trackingResult; context: { $implicit: result }"></ng-container>
                    }
                </div>
            </mat-option>
            } }
        </mat-autocomplete>
        <button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
            <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
    </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'"></ng-container>

<!-- Default result template -->
<ng-template #defaultResult let-result>
    <div class="flex items-center">
        <mat-icon class="mr-0 text-hint" [svgIcon]="result.link._type | icon : 'subject'"></mat-icon>
        <div class="ml-3 mr-4 truncate leading-normal" [innerHTML]="result.title"></div>
        <div class="flex gap-4">
            @for(hightlight of result.highlights | keys; track hightlight.key) {
            <div class="text-secondary text-md">
                found in {{ hightlight.key }}:
                <span class="p-2 border rounded-2xl bg-card" [innerHTML]="hightlight.value"></span>
            </div>
            }
        </div>
    </div>
</ng-template>

<ng-template #deviceResult let-result>
    <div class="flex items-center py-1 w-auto sm:space-x-2.5 bg-gradient-to-r from-cyan-500 to-lime-400 font-bold text-white rounded-full px-4 cursor-pointer">
        <div class="flex items-center">
            <span [colored]="result.data?.status_options?.color" class="py-1 px-2 rounded-full text-xs">{{ result.data?.status_options?.title }}</span>
        </div>

        @if(result.data.last_reply_on) {
        <div class="flex items-center space-x-1.5">
            <mat-icon svgIcon="logivice:sign_3" class="text-white icon-size-9 mr-0"></mat-icon>
            <span class="pt-1">Active</span>
        </div>
        }@else {
        <div class="flex items-center space-x-1.5 opacity-60">
            <mat-icon svgIcon="logivice:sign_3" class="text-white icon-size-9 mr-0"></mat-icon>
            <span class="pt-">--</span>
        </div>
        }

        <app-device-preview [device]="result.data" class="flex gap-1 bg-card rounded-2xl p-1 pl-6 bg-opacity-75" [expanded]="false"></app-device-preview>
        <div>@if(result.data?.address) { Located in {{ result.data?.address?.countryName }} } @else { Location unknown }</div>
    </div>

    @if(result.data?.traceables?.length) {
    <div class="flex flex-col gap-1">
        <div class="flex items-center gap-3">
            @for(traceable of result.data.traceables; track traceable.id) {
            <span class="font-bold">in</span>
            <span class="text-md">{{ traceable.traceable?.ref }}</span>

            <div class="flex items-center">
                <div class="w-3 h-3 rounded-full mr-2 bg-{{ traceable.status_options.color }}"></div>
                <span class="font-extralight text-sm">{{ traceable.status_options.title }}</span>
            </div>

            @if(traceable.traceable) {
            <a [routerLink]="traceable.traceable | urlTo" class="flex items-center" (click)="$event.stopPropagation(); close()">
                <mat-icon svgIcon="heroicons_outline:arrow-circle-right" class="icon-size-5 text-gray-300 hover:text-gray-500"></mat-icon>
            </a>
            } }
        </div>
    </div>
    }
</ng-template>

<ng-template #shipmentResult let-result>
    <div class="teal-bordered-card flex-row gap-4">
        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500">Status</div>
            <div [colored]="result.data.status_options.color" class="h-6 leading-6 items-baseline rounded-3xl font-bold px-5 uppercase text-sm text-center whitespace-nowrap">
                {{ result.data.status_options.title }}
            </div>
        </div>

        <!--countries-->
        @if(result.document.countries && result.document.countries.length === 2) {
        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500 leading-4">Route</div>
            <div class="flex items-center">
                <img class="w-10 rounded-sm" [src]="'assets/images/flags-rounded/' + (result.document.countries[0] | lowercase) + '.png'" [alt]="result.document.countries[0]" />
                <mat-icon svgIcon="mat_outline:arrow_right_alt" class="mr-0"></mat-icon>
                <img class="w-10 rounded-sm" [src]="'assets/images/flags-rounded/' + (result.document.countries[1] | lowercase) + '.png'" [alt]="result.document.countries[1]" />
            </div>
        </div>
        }

        <!--customers-->
        @if(result.document.customers && result.document.customers.length === 2) {
        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500">Customer</div>
            <div class="flex items-center">
                <b class="text-cyan-500">{{ result.document.customers[0] }}</b>
                <mat-icon svgIcon="mat_outline:arrow_right_alt" class="mr-0"></mat-icon>
                <b class="text-cyan-500">{{ result.document.customers[1] }}</b>
            </div>
        </div>
        }

        <!--dates-->
        @if(result.data.departure_date; as departureDate) {
        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500">Departure date</div>
            <div class="text-secondary">{{ departureDate | date : "MMM d y, hh:mm a" }}</div>
        </div>
        }

        <div class="flex flex-col">
            <div class="text-base font-bold text-slate-500">
                {{ result.data.delivered_at ? "Delivered date" : "Expected delivery date" }}
            </div>
            <div class="text-secondary">
                {{ result.data.delivered_at ?? result.data.expected_delivery_date | date : "MMM d y, hh:mm a" }}
            </div>
        </div>

        <!-- distance -->
        <div class="flex flex-col items-center">
            <div class="text-base font-bold text-slate-500">Transit distance</div>

            <span class="font-bold text-3xl text-lime-500">
                {{ result.data.route_distance | distance }}
            </span>
        </div>
    </div>
</ng-template>

<ng-template #trackingResult let-result>
    <div class="teal-bordered-card flex-row gap-4">
        <app-courier-tracking-status [tracking]="result.data"></app-courier-tracking-status>

        @if(result.data.company; as company) {
        <div class="flex items-center space-x-2">
            <img class="w-8 object-contain" [src]="company.icon_path" [alt]="company.display_name" *ngIf="company.icon_path; else courierIcon" />
            <ng-template #courierIcon>
                <mat-icon [svgIcon]="company.source | icon : 'courier_source'" class="icon-size-7 mr-0"></mat-icon>
            </ng-template>
            <span>{{ company.display_name }}</span>
        </div>
        }

        <!-- from -->
        @if(result.data.origin; as origin) {
        <div class="flex items-center">
            <img class="w-10 rounded-sm" [src]="'assets/images/flags-rounded/' + (origin?.country_iso_2 | lowercase) + '.png'" [alt]="origin.country_name" />

            <!-- to -->
            <mat-icon svgIcon="mat_outline:arrow_right_alt" class="mr-0"></mat-icon>
            @if(result.data.destination; as destination) {
            <img class="w-10 rounded-sm" [src]="'assets/images/flags-rounded/' + (destination?.country_iso_2 | lowercase) + '.png'" [alt]="destination.country_name" />
            } @else {- }
        </div>
        }

        <div class="flex items-center text-sm text-secondary leading-5 gap-4">
            <div>
                <div>Pickup date</div>
                {{ (result.data.pickup_date | date : "MMM d y") || "--" }}
            </div>
            <div>
                <div class="pl-1">
                    <div>Departure date</div>
                    {{ (result.data.departure_date | date : "MMM d y") || "--" }}
                </div>
            </div>
            <div class="flex justify-between gap-4">
                <div>
                    <div>Discharge date</div>
                    {{ (result.data.discharge_date | date : "MMM d y") || "--" }}
                </div>
                <div class="text-right">
                    <div>Delivery date</div>
                    {{ (result.data.delivery_date | date : "MMM d y") || "--" }}
                </div>
            </div>
        </div>
    </div>
</ng-template>
