import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import courierStatuses from 'assets/json/courier-statuses.json';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Co2eComponent } from '@modules/common/carbon_footprints/co2e/co2e.component';
import { ICarbonFootprint } from '@models/carbon-footprint';
import { ICourierTracking, ICourierTrackingCheckpoint } from '@models/courier-tracking';

@Component({
    selector: 'app-courier-tracking-status',
    templateUrl: './status.component.html',
    imports: [NgIf, MatIconModule, Co2eComponent],
    standalone: true,
    styles: ':host { @apply flex gap-4 items-center };'
})
export class CourierTrackingStatusComponent implements OnChanges {
    @Input({ required: true }) tracking: ICourierTracking;
    @Input() checkpoint?: ICourierTrackingCheckpoint;
    @Input() includeCarbonFootprint = false;
    @Output() updated = new EventEmitter<ICourierTracking>();

    status: { title: string; class?: string; icon?: string };

    ngOnChanges(): void {
        const status = this.checkpoint?.status ?? this.tracking.status;

        if (!status) {
            this.status = null;
        }

        this.status = courierStatuses?.[this.tracking.company.source]?.[status] ||
            courierStatuses?.common[status] || { title: status };
    }

    onCalcCarbonFootprint(data: ICarbonFootprint): void {
        this.updated.emit({ ...this.tracking, carbon_footprint: data });
    }
}
