import { Component, computed, input } from '@angular/core';
import { IPartnerBubbleState } from '@models/bubble';
import { IPartner } from '@models/partner';

type IPartnerBubbleIndicator = { color: string; animate: boolean; tooltip?: string };

@Component({
    selector: 'enter-as-section-partner-state-indicator',
    templateUrl: './partner-state-indicator.component.html',
    styles: ':host { @apply -ml-2 mr-4 cursor-pointer; }'
})
export class EnterAsSectionPartnerStateIndicatorComponent {
    partner = input.required<IPartner>();
    state = input<IPartnerBubbleState>();

    indicator = computed((): IPartnerBubbleIndicator | null => {
        const time = this.state()?.closest_hot_bubble?.minutes_left;

        const tooltip = 'closest hot bubble expires in about ' + time + ' mins';

        if (!time || time > 20) return { color: '#95CB5B', animate: false, tooltip: 'everything is fine' };
        if (time > 10) return { color: '#FF800C', animate: true, tooltip: tooltip };
        return { color: '#DE1616', animate: true, tooltip: tooltip };
    });
}
