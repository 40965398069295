<button mat-icon-button (click)="openPanel()" #tasksOrigin *ngrxLet="count$ as count">
    <ng-container *ngIf="count > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium">
                {{ count }}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:terminal'"></mat-icon>
</button>

<ng-template #tasksPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-md overflow-hidden shadow-lg">
        <!-- Header -->
        <div class="flex shrink-0-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary" *ngrxLet="count$ as count">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">Background Tasks</div>
            <div class="ml-auto">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card" *ngIf="tasks$ | ngrxPush as tasks">
            <ng-container *ngFor="let task of tasks.data">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <a class="flex flex-auto py-5 px-6 cursor-pointer">
                        <ng-container *ngTemplateOutlet="taskContent"></ng-container>
                    </a>

                    <ng-template #taskContent>
                        <div class="flex flex-col flex-auto">
                            <div class="flex justify-between items-center">
                                <div class="font-semibold line-clamp-1">{{ getType(task.type) }}</div>
                                <div class="flex items-center">
                                    <div class="text-sm text-secondary mr-2">{{ task.status }}</div>
                                    <button
                                        *ngIf="task.output?.statuses"
                                        class="w-6 h-6 min-h-6 sm:hidden sm:group-hover:block"
                                        mat-icon-button
                                        (click)="openDetails(task)"
                                        [matTooltip]="'Details'"
                                    >
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
                                    </button>
                                    <button class="w-6 h-6 min-h-6 sm:hidden sm:group-hover:block" mat-icon-button (click)="delete(task)" [matTooltip]="'Remove'">
                                        <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div class="line-clamp-2" *ngIf="task.output?.message">{{ task.output?.message }}</div>
                            <div class="flex justify-between items-center">
                                <div>
                                    <button mat-icon-button *ngIf="task.output?.filepath" (click)="downloadFile(task.output?.filepath)">
                                        <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:download'"></mat-icon>
                                    </button>
                                </div>
                                <div class="mt-2 text-sm leading-none text-secondary">
                                    {{ task.created_at | date : "MMM dd, h:mm a" }}
                                </div>
                            </div>
                            <div class="flex items-center mt-2" *ngIf="task.status === 'executing'">
                                <mat-progress-bar [color]="'accent'" [mode]="'indeterminate'"></mat-progress-bar>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="(count$ | async) === 0">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-primary-500" [svgIcon]="'heroicons_outline:terminal'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No background tasks</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">When you have background tasks, they will appear here.</div>
                </div>
            </ng-container>
        </div>

        <div class="flex justify-end bg-card p-2 border-t border-solid border-gray-200" *ngIf="(count$ | async) !== 0">
            <button mat-flat-button (click)="deleteAll()">Clear all</button>
        </div>
    </div>
</ng-template>

<ng-template #detailsTemplate let-task="task">
    <div class="p-4">
        <div class="font-bold">{{ task.output?.message }}</div>
        <br />

        <div class="flex flex-col divide-y divide-solid divide-gray-300">
            <div class="flex flex-row" *ngFor="let item of task.output?.statuses || [] | keys">
                <span class="font-bold">{{ item.key }}</span>
                <div class="flex flex-col ml-4">
                    <span>{{ item.value.status }}</span>
                    <span class="text-xs text-red-500">{{ item.value.error }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>
