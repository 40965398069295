<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'" [navigation]="navigation" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8" *ngrxLet="organisation$; let organisation">
                <img *ngIf="organisation?.logo as logo" [src]="logo" alt="subject logo" class="h-full max-w-24 object-contain" />
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        [tourAnchor]="'ucontrol.basic'"
    >
        <ng-container *ngIf="!isScreenSmall">
            <app-logo [type]="'icon'"></app-logo>
            <!-- Horizontal navigation -->
            <fuse-horizontal-navigation class="mr-2" [name]="'mainNavigation'" [navigation]="navigation"></fuse-horizontal-navigation>
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <search [appearance]="'bar'"></search>

            <button mat-icon-button [matTooltip]="'Run available guides'" (click)="guideService.restart.next(null)" [disabled]="!guideService.guideAvailable">
                <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon>
            </button>

            <a mat-icon-button [routerLink]="['/docs']" [matTooltip]="'API documentation'" *ngxPermissionsOnly="['ucontrol.api-docs']">
                <mat-icon svgIcon="heroicons_outline:code"></mat-icon>
            </a>

            <theme-switcher></theme-switcher>
            <notifications></notifications>
            <tasks></tasks>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full relative">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>

        <app-dyn-sidebar></app-dyn-sidebar>
    </div>

    <app-request-create></app-request-create>

    <!-- Footer -->
    <!--    <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
    <!--        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
    <!--    </div>-->
</div>
