import { Component, DestroyRef, Input, OnChanges } from '@angular/core';
import { Subscription, takeWhile, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CubeOrientation, cubeUtils } from '@shared/components/cube-visualization/cube.utils';

@Component({
    selector: 'app-cube-visualization',
    templateUrl: './cube-visualization.component.html',
    styleUrls: ['./cube-visualization.component.scss']
})
export class CubeVisualizationComponent implements OnChanges {
    @Input() position: number[] = [];
    @Input() showDetails = false;
    @Input() version = 1;

    orientation: CubeOrientation;
    transform: string;
    flipped: boolean;
    timer: Subscription;

    constructor(private destroyRef: DestroyRef) {}

    ngOnChanges(): void {
        const [x, y, z] = this.position;

        this.orientation = cubeUtils.calculatePitchAngles(x, y, z);
        this.flipped = cubeUtils.isBoxFlippedDown(x, y, z);

        this.render();

        if (this.timer) {
            this.timer.unsubscribe();
        }

        this.timer = timer(1000, 700)
            .pipe(
                takeUntilDestroyed(this.destroyRef),
                takeWhile(num => num < 100)
            )
            .subscribe(() => this.animateRotation());
    }

    render(): void {
        const { pitchX, pitchY, pitchZ } = this.orientation;

        this.transform = 'rotateX(' + pitchX + 'deg) rotateY(' + pitchY + 'deg) rotateZ(' + pitchZ + 'deg)';
    }

    animateRotation(): void {
        if (Math.abs(this.orientation.pitchX) > 135 && Math.abs(this.orientation.pitchX) < 225) {
            this.orientation.pitchY = this.orientation.pitchY - 10;
        } else if (Math.abs(this.orientation.pitchX) > 45 && Math.abs(this.orientation.pitchX) < 135) {
            this.orientation.pitchZ = this.orientation.pitchZ + 10;
        } else {
            this.orientation.pitchY = this.orientation.pitchY + 10;
        }

        this.render();
    }
}
