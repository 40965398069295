<!-- Navigation -->
<fuse-vertical-navigation
    #navigationComponent
    class="dark print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <div class="text-white text-center font-bold tracking-widest mt-1">NOTEBOOK</div>
        <div class="flex items-center justify-center h-18 pt-1 px-2 pb-3 border-b border-b-white mb-8" *ngrxLet="logo$; let logo">
            <div class="h-full w-full full-navigation-logo" [style.background-image]="'url(' + (logo || '/assets/images/logo/logo.png') + ')'"></div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="book-content flex flex-col flex-auto w-full min-w-0 rounded-bl-[20px]" *ngrxLet="organisation$; let organisation">
    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
