import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import {
    bubbleDetailRelations,
    bubbleListRelations,
    IBubble,
    IBubbleFacet,
    IBubbleFilter,
    IBubbleSections,
    IBubbleStatePerPartner
} from '@models/bubble';
import { map } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';
import { SuccessResponseType } from '@core/types/http/respone.type';
import { ExtendedListableResponseType } from '@core/types/http/listable-response.type';
import { AbstractDataSourceService } from '@core/services/resource/base/data-source.service';
import { filterUtils } from '@utils/filter-utils';

@Injectable({
    providedIn: 'root'
})
export class BubbleService extends AbstractDataSourceService<IBubble, IBubbleFilter> {
    resourceUrl = 'bubbles';
    withListRelations = bubbleListRelations;
    withDetailRelations = bubbleDetailRelations;

    constructor(protected httpService: ApiHttpService) {
        super(httpService);
    }

    loadPaginated(filter: IBubbleFilter): Observable<ExtendedListableResponseType<IBubble, IBubbleFacet>> {
        const normalizedFilter = filterUtils.rename(filter, {
            assignee_id: 'assignedUsers.user_id',
            team_id: 'assignedTeams.team_id',
            action_id: 'history.action_id'
        });

        return this.httpService
            .get<any>(this.createUrl('bubbles', normalizedFilter))
            .pipe(map((response: any) => response.data));
    }

    loadNavigationSections(): Promise<IBubbleSections> {
        return firstValueFrom(
            this.httpService
                .get<any>(this.createUrl('bubbles/sections'))
                .pipe(map((response: SuccessResponseType<IBubbleSections>) => response.data))
        );
    }

    loadStatePerPartner(): Observable<IBubbleStatePerPartner> {
        return this.httpService
            .get<any>(this.createUrl('bubbles/state_per_partner'))
            .pipe(map((response: SuccessResponseType<IBubbleStatePerPartner>) => response.data));
    }

    action(id: number, action: string, data: any = {}): Promise<IBubble> {
        return firstValueFrom(
            this.httpService
                .post<any>(this.createUrl(`${this.resourceUrl}/${id}/action/${action}`), {
                    ...data,
                    //bubbleable loaded by default
                    with: this.withDetailRelations.filter(r => r !== 'bubbleable').join(',')
                })
                .pipe(map((response: any) => response.data))
        );
    }
}
