import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPagination } from '@models/pagination';
import { QueryStringParameters } from '@core/services/services/query-string-parameters';
import { ITeam } from '@models/team';
import { AbstractDataSourceService } from '@core/services/resource/base/data-source.service';
import { Filter } from '@angular-architects/ngrx-toolkit/lib/with-data-service';

@Injectable({
    providedIn: 'root'
})
export class TeamsService extends AbstractDataSourceService<ITeam, Filter> {
    resourceUrl = 'teams';
    withListRelations = ['users'];
    withDetailRelations = ['users'];

    constructor(protected httpService: ApiHttpService) {
        super(httpService);
    }

    loadTeams(params: { [key: string]: any } = {}): Observable<ITeam[] | IPagination<ITeam>> {
        return this.httpService
            .get<any>(
                this.createUrlWithQueryParameters('teams', (qs: QueryStringParameters) => {
                    for (const key of Object.keys(params)) {
                        qs.push(key, params[key]);
                    }
                })
            )
            .pipe(map((response: any) => response.data));
    }
}
