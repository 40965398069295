import { EntityId } from '@ngrx/signals/entities';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuccessResponseType } from '@core/types/http/respone.type';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { ResourceService } from '@core/services/resource/resource.service';
import { DataService } from '@angular-architects/ngrx-toolkit';
import { Filter } from '@angular-architects/ngrx-toolkit/lib/with-data-service';
import { Identifiable } from '@core/types/model.types';

export class AbstractDataSourceService<Entity extends Identifiable, F extends Filter>
    extends ResourceService
    implements DataService<Entity, F>
{
    resourceUrl = '';
    withListRelations = [];
    withDetailRelations = [];

    constructor(protected httpService: ApiHttpService) {
        super();
    }

    loadById(id: EntityId): Promise<Entity> {
        return firstValueFrom(
            this.httpService
                .get<any>(this.createUrl(`${this.resourceUrl}/${id}`, { with: this.withDetailRelations.join(',') }))
                .pipe(map((response: SuccessResponseType<Entity>) => response.data))
        );
    }

    load(filter: Filter): Promise<Entity[]> {
        return firstValueFrom(
            this.httpService
                .get<any>(
                    this.createUrl(this.resourceUrl, {
                        ...filter,
                        with: this.withListRelations.join(','),
                        responseFormat: 'list'
                    })
                )
                .pipe(map((response: SuccessResponseType<Entity[]>) => response.data))
        );
    }

    create(entity: Entity): Promise<Entity> {
        return firstValueFrom(
            this.httpService
                .post(this.createUrl(this.resourceUrl), entity)
                .pipe(map((response: SuccessResponseType<Entity>) => response.data))
        );
    }

    update(entity: Entity): Promise<Entity> {
        return firstValueFrom(
            this.httpService
                .put(this.createUrl(`${this.resourceUrl}/${entity.id}`), entity)
                .pipe(map((response: SuccessResponseType<Entity>) => response.data))
        );
    }

    updateOne(id: number, data: Partial<Entity>): Promise<Entity> {
        return firstValueFrom(
            this.httpService
                .put(this.createUrl(`${this.resourceUrl}/${id}`), { ...data, with: this.withDetailRelations.join(',') })
                .pipe(map((response: SuccessResponseType<Entity>) => response.data))
        );
    }

    updateAll(entity: Entity[]): Promise<Entity[]> {
        throw new Error('updateAll method not implemented.');
    }

    delete(entity: Entity): Promise<void> {
        return firstValueFrom(this.httpService.delete<void>(this.createUrl(`${this.resourceUrl}/${entity.id}`)));
    }

    action(id: number, action: string, data: any = {}): Promise<Entity> {
        return firstValueFrom(
            this.httpService
                .post<any>(this.createUrl(`${this.resourceUrl}/${id}/action/${action}`), {
                    ...data,
                    with: this.withDetailRelations.join(',')
                })
                .pipe(map((response: any) => response.data))
        );
    }
}
