export type CubeOrientation = { pitchX: number; pitchY: number; pitchZ: number };

function calculatePitchAngles(xAxis, yAxis, zAxis): CubeOrientation {
    const realX = xAxis;
    const realY = zAxis; // Mapping Z-axis to Y in CSS
    const realZ = yAxis; // Mapping Y-axis to Z in CSS

    // Adjustments for the direction of Z-axis
    const zDirection = zAxis >= 0 ? 1 : -1;

    // Pitch for Axis X (rotateX)
    const pitchX = Math.atan2(realX, zDirection * Math.sqrt(realY ** 2 + realZ ** 2)) * (180 / Math.PI);

    // Pitch for Axis Y (rotateY)
    const pitchY = Math.atan2(realY, Math.sqrt(realX ** 2 + realZ ** 2)) * (180 / Math.PI);

    // Pitch for Axis Z (rotateZ)
    const pitchZ = Math.atan2(realZ, Math.sqrt(realX ** 2 + realY ** 2)) * (180 / Math.PI);

    return { pitchX, pitchY, pitchZ };
}

function isBoxFlippedDown(xAxis, yAxis, zAxis): boolean {
    const realX = xAxis;
    const realY = zAxis; // Mapping Z-axis to Y in CSS
    const realZ = yAxis; // Mapping Y-axis to Z in CSS

    // Adjustments for the direction of Z-axis
    const zDirection = zAxis >= 0 ? 1 : -1;

    // Pitch for Axis X (rotateX)
    const pitchX = Math.atan2(realX, zDirection * Math.sqrt(realY ** 2 + realZ ** 2)) * (180 / Math.PI);

    // Check if the box is flipped down
    return Math.abs(pitchX) > 135;
}

export const cubeUtils = {
    calculatePitchAngles,
    isBoxFlippedDown
};
