import { Pipe, PipeTransform } from '@angular/core';
import { urlUtils } from '@utils/url-utils';
import { Identifiable } from '@core/types/model.types';

@Pipe({
    name: 'urlTo'
})
export class UrlToPipe implements PipeTransform {
    transform(target: Identifiable | string): string {
        if (typeof target === 'string') {
            return urlUtils.makeUrlToSection(target);
        }

        return urlUtils.makeUrlTo(target);
    }
}
