import { Injectable } from '@angular/core';
import { ApiHttpService } from '@core/services/services/api-http.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ResourceService } from '@core/services/resource/resource.service';
import { IAuthProvider, IAuthProviders, IUser, User } from '@models/user';
import { SuccessResponseType } from '@core/types/http/respone.type';
import { BroadcastChanelRequestType } from '@core/types/http/request.type';
import { AuthDispatchers, AuthSelectors } from '@store/auth';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService extends ResourceService {
    firstInit = true;
    redirectUrl: string;

    constructor(
        protected httpService: ApiHttpService,
        protected authSelectors: AuthSelectors,
        protected authDispatchers: AuthDispatchers
    ) {
        super();
    }

    loadUserIfFirstInit(): void {
        if (this.firstInit) {
            this.authDispatchers.loadUser();
            this.firstInit = false;
        }
    }

    login(credentials: { email: string; password: string; remember: boolean }): Observable<any> {
        return this.httpService
            .get<any>(this.createUrl('csrf-cookie'))
            .pipe(switchMap(() => this.httpService.post(this.createUrl('login'), credentials)));
    }

    loginViaProvider(provider: IAuthProvider): void {
        const url = environment.apiEndpoint || 'http://localhost:8081';

        window.location.href = url + `/auth/${provider.slug}/${provider.connection_id}/redirect`;
    }

    loadProviders(): Observable<IAuthProviders> {
        return this.httpService
            .get(this.createUrl('auth/providers'))
            .pipe(map((response: SuccessResponseType<IAuthProviders>) => response.data));
    }

    twoFactorChallenge(data: {
        // @ts-ignore
        [key: 'code' | 'recovery_code']: string;
    }): Observable<any> {
        return this.httpService.post(this.createUrl('two-factor-challenge'), data);
    }

    logout(): Observable<any> {
        return this.httpService.post(this.createUrl('logout'), {}).pipe(map((response: any) => response));
    }

    authorizePrivateBroadcast(channel: BroadcastChanelRequestType): Observable<any> {
        return this.httpService.post<BroadcastChanelRequestType>(this.createUrl('user/broadcast'), channel);
    }

    loadUser(): Observable<User> {
        return this.httpService
            .get(this.createUrl('me/profile'))
            .pipe(map((response: SuccessResponseType<User>) => response.data));
    }

    disableGuides(names: string[]): Observable<User> {
        return this.httpService
            .post(this.createUrl('me/disable-guides'), { guide_names: names })
            .pipe(map((response: SuccessResponseType<User>) => response.data));
    }

    setCurrentOrganisation(organisationId: number): Observable<User> {
        return this.httpService
            .post(this.createUrl(`me/current-organisation/${organisationId}`), {})
            .pipe(map((response: SuccessResponseType<User>) => response.data));
    }

    changePassword(data: { old_password: string; password: string; password_confirmation: string }): Observable<any> {
        return this.httpService
            .post<any>(this.createUrl('me/change-password'), data)
            .pipe(map((response: any) => response));
    }

    updateProfile(data: any): Observable<any> {
        return this.httpService
            .post<any>(this.createUrl('me/update-profile'), data)
            .pipe(map((response: any) => response.data));
    }

    uploadAvatar(data: any): Observable<string> {
        return this.httpService
            .post(this.createUrl('me/upload-avatar'), data)
            .pipe(map((response: any) => response.data));
    }

    acceptInvitation(data: any): Observable<IUser> {
        return this.httpService
            .post<any>(this.createUrl('auth/accept-invitation'), data)
            .pipe(map((response: any) => response.data));
    }

    sendPasswordResetLink(email: string): Observable<any> {
        return this.httpService
            .get<any>(this.createUrl('csrf-cookie'))
            .pipe(switchMap(() => this.httpService.post<any>(this.createUrl('forgot-password'), { email })));
    }

    resetPassword(data: {
        email: string;
        password: string;
        password_confirmation: string;
        token: string;
    }): Observable<any> {
        return this.httpService
            .get<any>(this.createUrl('csrf-cookie'))
            .pipe(switchMap(() => this.httpService.post<any>(this.createUrl('reset-password'), data)));
    }
}
