<ng-container *ngIf="organisation$ | ngrxPush; let organisation">
    <ng-container *ngIf="organisation.allowed_partners.length === 1; else moreThanOnePartner">
        <img *ngIf="organisation.allowed_partners[0]?.[type + '_path'] as img" [src]="img" alt="subject logo" class="h-14 object-contain" />
    </ng-container>
    <ng-template #moreThanOnePartner>
        <div class="flex items-center" *ngrxLet="metainfo$; let metainfo">
            <img *ngIf="organisation?.[type] as img" [src]="img" alt="subject logo" class="h-14 object-contain" />
            <mat-icon svgIcon="mat_outline:chevron_right" class="transition-all ease-in-out duration-100" *ngIf="['uops', 'threepl'].indexOf(metainfo?.type) > -1"></mat-icon>
            <ng-container *ngIf="metainfo?.[type] && ['uops'].indexOf(metainfo.type) > -1">
                <img [src]="metainfo?.[type]" alt="subject logo" class="h-10 object-contain" />
            </ng-container>
        </div>
    </ng-template>
</ng-container>
