<!-- Navigation -->
<fuse-vertical-navigation
    #navigationComponent
    class="dark bg-gray-900 print:hidden"
    [appearance]="navigationAppearance"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 p-2" *ngrxLet="organisation$; let organisation">
            <div class="h-full w-full navigation-logo" [style.background-image]="'url(' + (organisation?.icon || '/assets/images/logo/logo.png') + ')'"></div>
            <div class="h-full w-full full-navigation-logo" [style.background-image]="'url(' + (organisation?.logo || '/assets/images/logo/logo.png') + ')'"></div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0" *ngrxLet="organisation$; let organisation">
    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden" *ngrxLet="user$; let user">
        <div class="flex items-center pr-2 space-x-2">
            <!-- Navigation toggle button -->
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
            <!-- Navigation appearance toggle button -->
            <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
                <mat-icon [svgIcon]="'heroicons_outline:switch-horizontal'"></mat-icon>
            </button>
        </div>
        <ng-container>
            <div class="flex items-center mr-auto">
                <app-logo [type]="'logo'"></app-logo>
            </div>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-2" *ngIf="user">
            <ng-container *ngIf="(section$ | ngrxPush) === 'uops'">
                @if(partner$ | ngrxPush; as partner) {
                <div class="mr-3">
                    <b>Account manager:</b>
                    {{ partner.manager_in_charge?.name || "none" | titlecase }}
                </div>
                }
            </ng-container>

            <search [appearance]="'bar'"></search>

            <!--            <shortcuts [shortcuts]="data.shortcuts"></shortcuts>-->
            <notifications></notifications>
            <tasks></tasks>
            <user-menu></user-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <app-request-create></app-request-create>

    <!-- Footer -->
    <!--    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">-->
    <!--        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>-->
    <!--    </div>-->
</div>
