import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { localStorageMigrations } from '@core/config/migrations';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private readonly storage: Storage;

    constructor(
        /* tslint:disable-next-line:ban-types */
        @Inject(PLATFORM_ID) private platformId: any
    ) {
        this.storage = window.localStorage;
    }

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }

    fetchJson(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    saveJson(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    migrate(): void {
        const migrationNumKey = 'migration.num';
        const migrations = localStorageMigrations;
        const migrationNumber = parseInt(this.getItem(migrationNumKey) || '0');

        for (let i = migrationNumber; i < migrations.length; i++) {
            migrations[i](this);

            // Store the last run migration as a number in localStorage
            this.setItem(migrationNumKey, (i + 1).toString());
        }
    }
}
