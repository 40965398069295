<div class="w-full" *ngIf="columns() as columns">
    <div class="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    @for(column of columns | keys; track column.key) {
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        {{ column.key }}
                    </th>
                    }
                </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
                @for(row of rows(); track row) {
                <tr>
                    @for(cell of row | keys; track cell.key; let i = $index) {
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            @switch (columns[cell.key]) {
                            <!--date-->
                            @case ('datetime') {
                            {{ cell.value | date : "MMM dd y, h:mm" }}
                            }
                            <!--colored-->
                            @case ('colored') {
                            <div [colored]="cell.value.color" class="inline-block px-3 rounded-full uppercase font-bold">
                                {{ cell.value.value }}
                            </div>
                            }
                            <!--link-->
                            @case ('link') { @if(cell?.value?.target){
                            <a [routerLink]="[cell.value.target | urlTo]" target="_blank" class="cursor-pointer hover:text-blue-600">
                                {{ cell.value.text }}
                            </a>
                            } }
                            <!--default-->
                            @default {
                            {{ cell.value }}
                            } }
                        </div>
                    </td>
                    }
                </tr>
                }
            </tbody>
        </table>
    </div>
</div>
