<ng-container *ngrxLet="organisation$; let organisation">
    <ng-container *ngIf="organisation; else goToOrganisationSelector">
        <div class="enter-as-section fixed top-24 right-0 flex flex-col items-end z-999" [class.opened]="enterAsDrawer.opened" *ngIf="displayEnterAs$ | ngrxPush">
            <div
                class="enter-as-button flex items-center justify-center w-12 h-12 shadow-lg rounded-l-lg cursor-pointer bg-red-600 bg-opacity-90 print:hidden z-999"
                (click)="enterAsDrawer.toggle()"
            >
                <mat-icon class="animate-pulse icon-size-8 text-white" [svgIcon]="'mat_outline:elevator'"></mat-icon>
            </div>

            <ng-container *ngIf="selected === 'ucontrol' || selected === 'cockpit'">
                <div class="enter-as-selected-partners flex flex-col-reverse hover:right-0 transition-all duration-300 ease-in-out cursor-pointer print:hidden partners-icons mt-2 z-999">
                    <img
                        *ngFor="let partner of selectedPartners$ | ngrxPush"
                        class="w-8 h-8 object-cover rounded-full bg-white partner-icon transition-all duration-300 ease-in-out border-solid border border-gray-700 shadow-xl"
                        [src]="partner.icon_path"
                        [matTooltip]="partner.display_name"
                        [alt]="partner.display_name"
                    />
                </div>
            </ng-container>
        </div>

        <fuse-drawer class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999" fixed transparentOverlay [mode]="'over'" [name]="'settingsDrawer'" [position]="'right'" #enterAsDrawer>
            <div class="flex flex-col justify-between w-full h-full overflow-auto">
                <enter-as-section
                    #enterAsSectionComponent
                    class="w-full h-full"
                    [directLinks]="directLinks"
                    (selectedChanged)="onSelectedChanged($event)"
                    (exit)="enterAsDrawer.close()"
                ></enter-as-section>
            </div>
        </fuse-drawer>
    </ng-container>
</ng-container>

<ng-template #goToOrganisationSelector>
    <div class="enter-as-section fixed top-24 right-0 flex flex-col items-end z-999">
        <div class="enter-as-button flex items-center justify-center w-12 h-12 shadow-lg rounded-l-lg cursor-pointer bg-red-600 bg-opacity-90 print:hidden z-999" (click)="goToEntrance()">
            <mat-icon class="animate-pulse icon-size-8 text-white" [svgIcon]="'mat_outline:elevator'"></mat-icon>
        </div>
    </div>
</ng-template>
